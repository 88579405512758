import React, { Component } from "react";
import Particles from "react-tsparticles";
import Slider from "react-slick";

import { mediaUrl } from "../../tools/config";
import LinkC from "../common/LinkC";
import Icon from "../common/Icon";
import InfoblockLink from "./InfoblockLink";
import PropTypes from "prop-types";
import { particlesOptions } from '../../tools/particles-options';
import AdvertisingLabeling from "../AdvertisingLabeling/AdvertisingLabeling";

export default class InfoBlockFullScreenCarousel extends Component {

    constructor(props) {
        super(props);

        this.settings = {
            dots: true,
            // infinite: false, //для снега
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            swipeToSlide: true,
            arrows: false,
            draggable: false,
            pauseOnHover: false,
            beforeChange: this.beforeChange,
        };

        this.state = {
            shown: [],
            current: 0,
            imgPath: this.getImgPath()
        }
    }

    getImgPath = () => {
        if (typeof window !== 'undefined') {
            if (window.innerWidth < 768) {
                return 'path_320';
            } else if (window.innerWidth < 1024) {
                return 'path_768';
            } if (window.innerWidth < 1440) {
                return 'path_1024';
            }
            return 'path_1440';
        }
        return null;
    }

    onResize = () => {
        let newSize = this.getImgPath();
        if (this.state.imgPath !== newSize) {
            this.setState({ imgPath: newSize })
        }
    }

    componentWillMount() {
        if (typeof window !== 'undefined') {
            window.addEventListener('resize', this.onResize)
        }
    }

    static contextTypes = {
        setHeaderClassName: PropTypes.func
    };

    blockTabInSlider = (e) => {
        if(e.keyCode === 9) {
            e.preventDefault();
            e.stopPropagation();
        }
    }

    componentDidMount() {
        var blockType = this.props.blocks[0].type;
        if (blockType.allow_auto_slick) {
            if (document.getElementsByClassName('slick-dots').length < 0) {
                var buttons = document.getElementsByClassName('slick-dots')[0].getElementsByTagName('button');
                for (var i = 0; i < buttons.length; i++) {
                    buttons[i].setAttribute("style", "animation-duration: " + blockType.auto_slick_time + "s;");
                }
            }
        }

        if (this.props.blocks[0].is_orange) {
            this.context.setHeaderClassName("page__header-holder page__header-holder_float page__header-holder_white holder_orange", true);
        }

        if(document !== 'undefined' && document.querySelector(".page__intro-slider")) {
            document.querySelector(".page__intro-slider").addEventListener('keydown', this.blockTabInSlider)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.current === 0 && this.props.blocks[0].is_orange) {
            this.context.setHeaderClassName("page__header-holder page__header-holder_float page__header-holder_white holder_orange");
        }

        // когда доходим до последнего слайда нас перекидывает на первый ЭТО ДЛЯ КОРРЕКТНОЙ РАБОТЫ СНЕГА
        if (this.state.current === this.props.blocks.length -1) {
            setTimeout(() => this.slider.slickGoTo(0), 1000 * this.props.blocks[0].type.auto_slick_time);
        }
        //
    }

    componentWillUnmount() {
        if (typeof window !== 'undefined') {
            window.removeEventListener('resize', this.onResize)
        }
        if(document !== 'undefined' && document.querySelector(".page__intro-slider")) {
            document.querySelector(".page__intro-slider").removeEventListener('keydown', this.blockTabInSlider)
        }
    }

    goBottom = (e) => {
        if (e) e.preventDefault();
        window.scrollTo(0, window.pageYOffset + 25);
        if (window.pageYOffset < window.innerHeight) {
            setTimeout(this.goBottom, 10);
        }

    }

    beforeChange = (oldIndex, newIndex) => {
        if (this.props.blocks[oldIndex].is_orange !== this.props.blocks[newIndex].is_orange) {
            this.context.setHeaderClassName("page__header-holder page__header-holder_float page__header-holder_white" + (this.props.blocks[newIndex].is_orange ? " holder_orange" : ""))
        }

        this.setState((prevState) => {
            prevState.shown.push(oldIndex);
            prevState.current = newIndex;
            return prevState;
        })
    }

    render() {

        let {
            blocks
        } = this.props;

        return (
            <div className="page__intro-slider">
                <div className="js-intro-slider b-intro-slider b-intro-slider_adapt_default">
                    <div className="b-intro-slider__wrapper">
                        <Slider ref={c => (this.slider = c)} {...this.settings} autoplay={blocks[0].type.allow_auto_slick} autoplaySpeed={1000 * blocks[0].type.auto_slick_time}>
                            {blocks.map((block, key) =>
                                <SlideInner key={block.alias}
                                    block={block}
                                    shown={this.state.shown}
                                    current={this.state.current === key}
                                    imgPath={this.state.imgPath}
                                    index={key}
                                />
                            )}
                        </Slider>
                        <div className="b-intro-slider__ui-area">
                            <div className="b-intro-slider__ui-container page__container">
                                {blocks.length > 1 ? <div className="b-intro-slider__arrow-section">
                                    <div className="b-intro-slider__arrow b-intro-slider__arrow_prev slick-arrow" onClick={() => { this.slider.slickPrev() }}>
                                        <Icon className="b-intro-slider__arrow-icon b-intro-slider__arrow-icon_prev u-icon u-icon_arrow-left-tr" name="icon_arrow-left-tr" />
                                    </div>
                                    <div className="b-intro-slider__arrow b-intro-slider__arrow_next slick-arrow" onClick={() => { this.slider.slickNext() }}>
                                        <Icon className="b-intro-slider__arrow-icon b-intro-slider__arrow-icon_next u-icon u-icon_arrow-left-tr" name="icon_arrow-left-tr" />
                                    </div>
                                </div> : null}
                                <a className="b-intro-slider__down-section" href="#" onClick={this.goBottom}>
                                    <span className="b-intro-slider__down-label">Вниз</span>
                                    <Icon className="b-intro-slider__down-icon u-icon u-icon_arrow-left-tr" name="icon_arrow-left-tr" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

class SlideInner extends Component {

    constructor(props) {
        super(props);
        this.video = React.createRef();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.video.current && this.video.current.paused && this.props.current && !prevProps.current) {
            this.video.current.play();
        }
        if (!this.props.current && prevProps.current && this.video.current) {
            this.video.current.pause();
            this.video.current.currentTime = 0;
        }
    }

    render() {

        const { block, shown, imgPath, index, current } = this.props;

        return (
            <div className={"b-intro-slider__slide" + (shown.indexOf(index) !== -1 ? " no-animation" : " ")} key={block.alias}>
                <div className='b-intro-slider__slide-inner__bg'>
                    {block.backgroundVideo
                        ? <video
                            ref={this.video}
                            poster={block.images && block.images.path_1440 ? mediaUrl + block.images.path_1440 : null}
                            loop
                            muted
                            playsInline
                            autoPlay
                        >
                            <source src={(block.backgroundVideo.path.indexOf('http') === 0 ? '' : mediaUrl) + block.backgroundVideo.path} type={block.backgroundVideo.type} />
                        </video>
                        : ((block.images && block.images.path_1440)
                            ? index === 0
                                ? <React.Fragment>
                                    <img alt="" key={1440} className='b-intro-slider__slide-inner__bg_img_1440' src={mediaUrl + block.images.path_1440} />
                                    <img alt="" key={1024} className='b-intro-slider__slide-inner__bg_img_1024' src={mediaUrl + block.images.path_1024} />
                                    <img alt="" key={768} className='b-intro-slider__slide-inner__bg_img_768' src={mediaUrl + block.images.path_768} />
                                    <img alt="" key={320} className='b-intro-slider__slide-inner__bg_img_320' src={mediaUrl + block.images.path_320} />
                                </React.Fragment>
                                : (imgPath ? <img alt="" key={'full'} src={mediaUrl + block.images[imgPath]} />
                                    : null) : <div style={{ background: 'lightgray', height: '100%' }} />
                        )}
                </div>
                <div className="b-intro-slider__slide-inner">
                    {/* СНЕГ НА НОВЫЙ ГОД */}
                    {/*{*/}
                    {/*   current &&*/}
                    {/*   <div className="wrap_tsparticles">*/}
                    {/*       <Particles*/}
                    {/*           id="tsparticles"*/}
                    {/*           canvasClassName="custom_canvas"*/}
                    {/*           options={particlesOptions}*/}
                    {/*       />*/}
                    {/*   </div>*/}
                    {/*}*/}
                    
                    <AdvertisingLabeling
                        token={block && block.token}
                        advertiser={block && block.advertiser}
                        type="mainType"
                    />

                    <div className="b-intro-slider__content page__container">
                        <div className="b-intro-slider__text-section">
                            <div className="b-intro-slider__title">
                                <div className="b-intro-slider__title-row b-intro-slider__title-row_first" style={block.color && block.color.title ? { color: block.color.title } : {}} dangerouslySetInnerHTML={{ __html: block.title }} />
                            </div>
                            <div className="b-intro-slider__subtitle" style={block.color && block.color.description ? { color: block.color.description } : {}} dangerouslySetInnerHTML={{ __html: block.text }} />
                        </div>
                        <div className="b-intro-slider__btn-area">
                            <InfoblockLink className={"b-intro-slider__btn u-btn u-btn_responsive u-btn_adapt_mobile-sm" + (block.is_orange ? ' u-btn_white' : ' u-btn_default')} item={block} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
