import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";

import RangeInput from '../common/RangeInput';
import TariffInfo from "../Constructor/TariffInfo";
import { getRounding, checkAllUrlParams, isEmptyObj, chekParams } from '../../tools/tools';
import TariffCalculatorNew from './TariffCalculatorNew';
import TariffCalculatorCheckboxes from '../TariffConstructor/TariffCalculatorCheckboxes';
import { checkWord, clearWord, clearActivationData } from "../../actions/activation";

import { RenderField, onChange } from "../../components/Rates/PopupHelpers";
import AdditionalServices from './AdditionalServices';
import Icon from "../common/Icon";
import { tariffConnectionToggleAction } from '../../actions/common';
import TooltipQuestion from '../common/TooltipQuestion/TooltipQuestion';
import { InfoModalUnlim } from "../../components/modals/constructor/InfoModal";
import { callLogin } from "../../actions/client";

class TariffConstructorNew extends Component {
    constructor(props) {
        super(props);

        let axisYVal = 0;
        let valueSvoiGb = 0;
        let axisXVal = 0;
        let valueMinutes = 0;

        let gb_default = 1,
            minutes_default = 0,
            data_unlim_default = 0,
            sms_pack_default = 0,
            social_unlim_default = 0,
            motiv_voice_unlim_default = 0,
            router_default = 0,
            sms_pack_count = 0,
            youtube_unlim_default = 0,
            ivi_default = 0,
            home_region_checked_default = 0;

        const defaultData = this.props.tariffConstructor && this.props.tariffConstructor.defaultData;
        let recalcData = this.props.tariffConstructor && this.props.tariffConstructor.recalcData;
        let defaultParams = this.props.defaultParams;

        if (!!defaultParams) {
            gb_default = defaultData.constructor && +defaultData.constructor.data;
            minutes_default = defaultData.constructor && +defaultData.constructor.min;
            if (defaultData.constructor && defaultData.constructor.options && defaultData.constructor.options.length > 0) {
                data_unlim_default = chekParams(defaultData.constructor.options, 'DATA_UNLIM');
                social_unlim_default = chekParams(defaultData.constructor.options, 'SOCIAL_UNLIM');
                motiv_voice_unlim_default = chekParams(defaultData.constructor.options, 'MVOICE_UNLIM');
                router_default = chekParams(defaultData.constructor.options, 'ROUTER');
                youtube_unlim_default = chekParams(defaultData.constructor.options, 'YOUTUBE_UNLIM');
                ivi_default = chekParams(defaultData.constructor.options, 'IVI');
                home_region_checked_default = chekParams(defaultData.constructor.options, 'HOME_REGION_CHECKED');
            }
        } else {
            gb_default = recalcData.constructor && +recalcData.constructor.data;
            minutes_default = recalcData.constructor && +recalcData.constructor.min;
            if (recalcData.constructor && recalcData.constructor.options && recalcData.constructor.options.length > 0) {
                data_unlim_default = chekParams(recalcData.constructor.options, 'DATA_UNLIM');
                social_unlim_default = chekParams(recalcData.constructor.options, 'SOCIAL_UNLIM');
                motiv_voice_unlim_default = chekParams(recalcData.constructor.options, 'MVOICE_UNLIM');
                router_default = chekParams(recalcData.constructor.options, 'ROUTER');
                youtube_unlim_default = chekParams(recalcData.constructor.options, 'YOUTUBE_UNLIM');
                ivi_default = chekParams(recalcData.constructor.options, 'IVI');
                home_region_checked_default = chekParams(recalcData.constructor.options, 'HOME_REGION_CHECKED');
            }
        }

        const xLength = props.axis && props.axis.x && props.axis.x.length;
        const maxValMinutes = props.axis && props.axis.x[xLength - 1];

        this.state = {
            focus: false,
            inputTitle: 'название',
            tariffInfo: {},
            max_traffic: 81,
            axisYVal,
            valueSvoiGb,
            focusSvoiGb: false,
            svoiGb_step: 1,
            axisXVal,
            valueMinutes,
            focusMinutes: false,
            minutes_step: 10,
            masOld: [0],
            masNew: [0],
            arrayGb: null,
            marksObjectGb: null,
            arrayMinutes: null,
            marksObjectMinutes: null,
            sms: 0,
            selectedRegion: null,
            flag: 0,
            maxValInternet: null,
            maxValMinutes,
            modal: '',
            validAllParamUrl: false,

            sms_pack_default,
            gb_default,
            sms_pack_count,
            disabled: false,
            urlItems: [],
            checkboxOptions: [],

            data_unlim: 0,
            router: 0,
            social_unlim: 0,
            motiv_voice_unlim: 0,
            youtube_unlim: 0,
            ivi: 0,
            home_region_checked: 0,

            data_unlim_default,
            router_default,
            social_unlim_default,
            motiv_voice_unlim_default,
            youtube_unlim_default,
            ivi_default,
            home_region_checked_default,

            dataChOn: true,
            routerChOn: true,
            socialChOn: true,
            voiceChOn: true,
            ytbChOn: true,
            iviChOn: true,
            homeChOn: true,

            smsChOn: true,
            disabledRqs: false,
            smsParamReq: false,

            optionSmsArr: [],
            optionOtherArr: [],
            tooltipOn: false,
            tooltipValue: '',
            priceMinMax: false,
            priceMinMaxValue: '',
            isSm: false,
            maxFixedPrice: false,
            cost_type: 0,   // 0 || 1
            user_cost: 0,    // cost_type?
            currentSmsPack: '',
            // currentSmsPack: 'SMS_PACK_0',
            // urlLink: '',
            first_in: 1,

            fixMarksObjectMinutes: {},
            fixMarksObjectGb: {},
            fixMinValMinutes: 0,
            fixMaxValMinutes: 0,
            fixMinValInternet: 0,
            fixMaxValInternet: 0,
            isZero: true
        }

        // this.onChange = onChange.bind(this);
        this.renderModal = this.renderModal.bind(this);
        this.tooltipRef = React.createRef();
        this.timeoutRef = React.createRef();
    }

    static contextTypes = {
        isGuest: PropTypes.bool,
        selectedRegion: PropTypes.object,
        fetchBefore: PropTypes.func,
        pushHistory: PropTypes.func,
        locationPath: PropTypes.string,

        constructorParams: PropTypes.object,
        toggleRegion: PropTypes.func
    };

    componentWillMount() {
        if (this.state.selectedRegion === null) {
            this.setState({ selectedRegion: this.context.selectedRegion });
            this.state.selectedRegion = this.context.selectedRegion
        }

        const axisY = this.props.axis && this.props.axis.y;
        if (!!axisY && !this.state.arrayGb) {
            let gbArr = this.getMarks(axisY, 10, 9);
            let marksObjectGb = this.getObjectMarks('gb', gbArr);
            this.setState({
                arrayGb: gbArr,
                marksObjectGb
            })
        }

        const axisX = this.props.axis && this.props.axis.x;
        if (!!axisX && !this.state.arrayMinutes) {
            let minutesArr = this.getMarks(axisX, 20);
            const cleanedArray = minutesArr.filter(item => item === 0 || item);
            let marksObjectMinutes = this.getObjectMarks('minutes', cleanedArray);
            this.setState({
                arrayMinutes: minutesArr,
                marksObjectMinutes
            })
        }

        let currentTariff = this.props.currentTariff
        let slugSvoi = currentTariff && currentTariff.slug.indexOf('svoi');

        if ((this.props.isGuest || !this.props.isGuest && slugSvoi === -1) && !this.state.validAllParamUrl) {
            if (!!this.props.defaultParams) {
                this.setParamsTariff(this.props.tariffConstructor.defaultData, 'default_setting');
            }
            if (!this.props.defaultParams) {
                this.setParamsTariff(this.props.tariffConstructor.recalcData, 'default_setting');
            }
        }

        // // удалено для first_in
        // if (!!currentTariff && !this.props.isGuest && slugSvoi !== -1 && !this.state.validAllParamUrl) {
        //     this.setParamsTariff(currentTariff);
        // }

    }

    componentDidMount() {
        let {
            tariffConstructor,
            isGuest,
            currentTariff
        } = this.props;
        // для закрытия тултипа
        document.addEventListener("mousedown", this.handleClickOutside);

        // if (!this.state.maxValInternet) {
        //     if (this.state.data_unlim_default) {
        //         let maxValInternet = this.maxValueObj(this.state.marksObjectGb) + 10;
        //         this.setState({
        //             maxValInternet
        //         });
        //     } else {
        //         let maxValInternet = this.maxValueObj(this.state.marksObjectGb)
        //         this.setState({
        //             maxValInternet
        //         });
        //     }
        // }

        if (window.innerWidth < 768) {
            this.setState({
                isSm: true
            });
        }

        // создаем новый массив элемтов с именем 'SMS_PACK'
        let optionsArr = tariffConstructor && tariffConstructor.defaultData && tariffConstructor.defaultData.options
        let findSmsOptions = [];
        optionsArr.forEach(element => {
            if (element.type.indexOf('SMS_PACK') === 0) {
                findSmsOptions.push(element)
            }
            this.setState({
                optionSmsArr: findSmsOptions,
            });
        });

        // меняет цену при рендере
        let defAbon = tariffConstructor && tariffConstructor.defaultData.abon;
        let recAbon = tariffConstructor && tariffConstructor.recalcData.abon;
        if (recAbon || defAbon) {
            let abon = recAbon || defAbon;
            this.setState({
                user_cost: +abon,
            });
        }
        //         if (tariffConstructor && tariffConstructor.defaultData.abon) {
        //             let abon = tariffConstructor.defaultData.abon;

        //             this.setState({
        //                 user_cost: +abon,
        //             });
        //         }

        // для блока смс
        // let curentPath = this.context.locationPath.split("/")[this.context.locationPath.split("/").length - 1];
        // if (!isGuest && currentTariff && curentPath === 'tariffs') {
        //     let smsCountClient = currentTariff && currentTariff.details && +currentTariff.details.sms
        //     // таймаут нужен что бы корректно отрабатывала функция
        //     setTimeout(() => {
        //         this.clientSmsCount(this.state.optionSmsArr, smsCountClient);
        //     }, 2000);
        // }
        // // нужно для перезапроса при переходе с других страниц на тарифы
        // if (this.props.clientInfo.username) {
        //     this.recountPrice();
        // }
        // // при первом рендеринге first_in
        // if (!this.props.isGuest && !isEmptyObj(this.props.tariffConstructor.recalcData)) {
        //     this.setParamsTariff(this.props.tariffConstructor.recalcData, 'default_auth');
        // }
    }

    componentDidUpdate(prevProps, prevState) {
        let {
            tariffConstructor,
            axis,
            constructorParams,
            tariffConnectionValue,
            currentTariff,
            isGuest,
            clientInfo
        } = this.props;

        let recalcData = tariffConstructor && tariffConstructor.recalcData;
        let prevRecalcData = prevProps.tariffConstructor && prevProps.tariffConstructor.recalcData;

        let abon = recalcData && recalcData.abon;
        let prevAbon = prevRecalcData && prevRecalcData.abon;
        let userCost = recalcData && recalcData.constructor && recalcData.constructor.user_cost;
        let prevUserCost = prevRecalcData && prevRecalcData.constructor && prevRecalcData.constructor.user_cost;
        let costType = recalcData && recalcData.constructor && recalcData.constructor.cost_type;
        let prevCostType = prevRecalcData && prevRecalcData.constructor && prevRecalcData.constructor.cost_type;
        let offer = recalcData && recalcData.offer_options;
        let prevOffer = prevRecalcData && prevRecalcData.offer_options;

        let constructor = recalcData && recalcData.constructor;

        if (prevState.disabledRqs !== this.state.disabledRqs && !!this.state.disabledRqs) {
            setTimeout(() => {
                if (!!this.state.disabledRqs) this.setState({ disabledRqs: false });
            }, 10000)
        }

        // меняем цену в инпуте при движении ползунков
        // изменение цены при зажатой блокировке цены
        if (costType !== prevCostType && costType) {
            this.setState({
                user_cost: +constructor.user_cost,
            });
        } else if (abon !== prevAbon && !costType) {
            this.setState({
                user_cost: +abon,
            });
        }

        // если выполняется условие то
        if (recalcData && prevRecalcData !== recalcData) {
            let recalcConstructorData = tariffConstructor && tariffConstructor.recalcData && tariffConstructor.recalcData.constructor;
            let offerOptions = tariffConstructor && tariffConstructor.recalcData && tariffConstructor.recalcData.offer_options;
            let axis = this.props.axis;
            // меняем параметры слайдеров минут и гб на приходящие
            // не меняет параметры при отжимании кнопки блокировки цены если цена повторяется
            if (userCost && userCost !== prevUserCost) {
                this.setState({
                    axisYVal: axis && axis.y.indexOf(constructor.data),
                    axisXVal: axis && axis.x.indexOf(constructor.min)
                });
            }

            // для фиксированной цены
            if (prevCostType !== costType && costType === 1 || userCost && userCost !== prevUserCost) {
                this.replaceMarksForInputRange();
            }

            // показываем мин макс цену под первым инпутом
            if (constructor && constructor.user_cost_available) {
                this.setState({
                    priceMinMax: true,
                    priceMinMaxValue: recalcConstructorData && recalcConstructorData.user_cost_available
                });

                setTimeout(() => {
                    this.setState({
                        priceMinMax: false,
                        priceMinMaxValue: ''
                    });
                }, 5000);
            }

            // показываем тултип под первым инпутом
            if ((offer && offer.surcharge_price) !== (prevOffer && prevOffer.surcharge_price)) {
                this.checkAvailableField();
            }

  
            if (!this.state.maxValInternet) {
                if (this.state.data_unlim_default) {
                    let maxValInternet = this.maxValueObj(this.state.marksObjectGb) + 10;
                    this.setState({
                        maxValInternet
                    });
                } else {
                    let maxValInternet = this.maxValueObj(this.state.marksObjectGb)
                    this.setState({
                        maxValInternet
                    });
                }
            }
        }


        // при изменении апи в зависимости от условий ставим безлимит или убираем
        if (prevProps.tariffConstructor.recalcData.constructor.cost_type !== tariffConstructor.recalcData.constructor.cost_type && tariffConstructor.recalcData.constructor.cost_type === 1) {
            let scope = tariffConstructor && tariffConstructor.recalcData && tariffConstructor.recalcData.scope;
            let dataUnlim = chekParams(constructor && constructor.options, 'DATA_UNLIM');
            let routerUnlim = chekParams(constructor && constructor.options, 'ROUTER');
            let socialUnlim = chekParams(constructor && constructor.options, 'SOCIAL_UNLIM');
            let motivVoiceUnlim = chekParams(constructor && constructor.options, 'MVOICE_UNLIM');
            let youtubeUnlim = chekParams(constructor && constructor.options, 'YOUTUBE_UNLIM');
            let iviUnlim = chekParams(constructor && constructor.options, 'IVI');
            let home = chekParams(constructor && constructor.options, 'HOME_REGION_CHECKED');


            // убираем unlim при первой загрузке
            if (constructor.data !== scope.data_max && dataUnlim) {
                this.offCheckboxChange('data_unlim');
            }

            if (constructor.data === scope.data_max && dataUnlim && scope.data_max !== 0) {
                this.onCheckboxChange('data_unlim');
            }

            if (constructor.data === scope.data_max && dataUnlim && this.state.data_unlim === 0) {
                this.onCheckboxChange('data_unlim');
            }

            // отключаем все чекбоксы при первой блокировке кнопки
            if (routerUnlim) {
                this.offCheckboxChange('router');
            }
            if (socialUnlim) {
                this.offCheckboxChange('social_unlim');
            }
            if (motivVoiceUnlim) {
                this.offCheckboxChange('motiv_voice_unlim');
            }
            if (youtubeUnlim) {
                this.offCheckboxChange('youtube_unlim');
            }
            if (iviUnlim) {
                this.offCheckboxChange('ivi');
            }
            if (home) {
                this.offCheckboxChange('home_region_checked');
            }
        }

        if (prevState.cost_type !== this.state.cost_type && !this.state.cost_type) {
            this.setState({
                maxFixedPrice: false
            })
        }

        // создаем новый массив элемтов не с именем 'SMS_PACK'
        if (prevProps.tariffConstructor.recalcData !== tariffConstructor.recalcData) {
            let optionsArr = tariffConstructor && tariffConstructor.recalcData && tariffConstructor.recalcData.options
            let findOtherOptions = [];
            optionsArr.forEach(element => {
                if (element.type.indexOf('SMS_PACK') === -1) {
                    findOtherOptions.push(element)
                }
                this.setState({
                    optionOtherArr: findOtherOptions
                })
            });
        }

        // при измененнии параметров записываем их и передаем родителю для финального модального окна
        let {
            axisYVal,
            axisXVal,
            data_unlim,
            router,
            social_unlim,
            motiv_voice_unlim,
            youtube_unlim,
            ivi,
            home_region_checked,
            currentSmsPack
        } = this.state;

        let sms_pack_count = parseInt((currentSmsPack === '' ? 'SMS_PACK_0' : currentSmsPack).match(/\d+/));
        // let sms_pack_count = parseInt(currentSmsPack.match(/\d+/));

        let axisY = axis && axis.y;
        let axisX = axis && axis.x;
        if (!isEmptyObj(constructorParams) && prevProps.constructorParams !== constructorParams) {
            let dataForUrl = {
                min: axisX[axisXVal],
                gb: !!axisY[axisYVal] ? axisY[axisYVal] : 'u',
                data: data_unlim,
                router: router,
                social: social_unlim,
                voice: motiv_voice_unlim,
                sms_pack_count,
                youtube_unlim,
                ivi,
                home_region_checked
            }

            this.props.getDataForUrl(dataForUrl);
        }

        // при нажатии на таб купить сим-карту меняем ссылки в зависимости от регионов
        // if (prevProps.tariffConnectionValue !== tariffConnectionValue && tariffConnectionValue === 'buy_sim_card') {
        //     let regionId = this.context.selectedRegion.id;
        //     let link = '';
        //     if (regionId === 66) {
        //         link = 'https://shop.motivtelecom.ru/catalog/sim/'
        //     }
        //     if (regionId === 45) {
        //         link = 'https://krg-shop.motivtelecom.ru/catalog/sim/'
        //     }
        //     if (regionId === 86) {
        //         link = 'https://hmao-shop.motivtelecom.ru/catalog/sim/'
        //     }
        //     if (regionId === 89) {
        //         link = 'https://yanao-shop.motivtelecom.ru/catalog/sim/'
        //     }
        //     this.setState({ urlLink: link });
        //     this.props.tariffConnectionToggleAction('quick');
        // }

        // для блока смс
        // if (!isGuest && currentTariff && currentTariff !== prevProps.currentTariff) {
        //     let smsCountClient = currentTariff && currentTariff.details && +currentTariff.details.sms;

        //     // таймаут нужен что бы корректно отрабатывала функция
        //     setTimeout(() => {
        //         this.clientSmsCount(this.state.optionSmsArr, smsCountClient);
        //     }, 2000);
        // }
        // очищаем смски при выходе из автор зоны и добавляем дефолтные значения
        if (isGuest && isGuest !== prevProps.isGuest) {
            this.setState({
                currentSmsPack: '',
                first_in: 1
            }, () => {
                this.setParamsTariff(this.props.tariffConstructor.defaultData, 'default_setting');
            });
        }

        // делаем первый запрос что бы получить дефолтные значения
        let userName = clientInfo && clientInfo.username;
        let prevUserName = prevProps.clientInfo && prevProps.clientInfo.username;

        if ((userName) !== (prevUserName) && userName && this.state.first_in) {
            this.recountPrice();
        }
        if (userName && prevRecalcData !== recalcData && !isEmptyObj(recalcData) && this.state.first_in) {
            this.setState({
                first_in: 0
            }, () => {
                this.setParamsTariff(this.props.tariffConstructor.recalcData, 'default_auth');
            });
        }

        if (prevProps.tariffConnectionValue !== tariffConnectionValue) {
            this.recountPrice();
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.tariffConstructor.recalcData.constructor !== nextProps.tariffConstructor.recalcData.constructor) {
            if (typeof window !== "undefined") {
                const urlParams = checkAllUrlParams();

                // в созданный массив записываем новое поле sms_count
                let newArr = this.state.optionSmsArr
                newArr.forEach(function (item) {
                    item['sms_count'] = parseInt(item.type.match(/\d+/));
                })

                if ((!!urlParams && !this.state.smsParamReq)) {
                    if (!this.state.smsParamReq) this.setState({ smsParamReq: true });
                    this.setUrlParams(urlParams, newArr);
                }
            }
        }

        let currentTariff = this.props.currentTariff;


        if ((JSON.stringify(this.props.tariffConstructor.recalcData) !== JSON.stringify(nextProps.tariffConstructor.recalcData)) || nextProps.tariffConstructor.recalcData && isEmptyObj(this.state.tariffInfo)) {
            if (!isEmptyObj(nextProps.tariffConstructor.recalcData)) {
                this.setState({ tariffInfo: this.combineTariffInfo(nextProps.tariffConstructor.recalcData) });
            }
        }

        //         if (this.props.isGuest !== nextProps.isGuest && nextProps.isGuest ||
        //             !this.props.isGuest && currentTariff !== nextProps.currentTariff && nextProps.currentTariff && nextProps.currentTariff.slug.indexOf('svoi') === -1 && !this.state.validAllParamUrl) {
        //             this.setParamsTariff(this.props.tariffConstructor.defaultData, 'default_setting');
        //         }

        // отрабатывает запрос в авторизованной зоне при отсутствии квери параметров
        //         if (!this.props.isGuest && currentTariff !== nextProps.currentTariff && nextProps.currentTariff && nextProps.currentTariff.slug.indexOf('svoi') !== -1 && !this.state.validAllParamUrl) {
        //             this.setParamsTariff(nextProps.currentTariff);
        //         }

        const axis = nextProps.axis;
        let { axisXVal, axisYVal } = this.state;

        if (axis.y[axisYVal] !== this.state.valueSvoiGb) {
            this.setState({
                valueSvoiGb: axis.y[axisYVal],
                valueSvoiGbOld: axis.y[axisYVal],
            })
        }

        if (axis.x[axisXVal] !== this.state.valueMinutes) {
            this.setState({
                valueMinutes: axis.x[axisXVal],
                valueMinutesOld: axis.x[axisXVal]
            })
        }

        if (this.props.tariffConstructor.errors !== nextProps.tariffConstructor.errors && !!nextProps.tariffConstructor.errors.message && !this.state.disabled) {
            this.setState({ disabled: true })
        }

        if (this.props.tariffConstructor.errors !== nextProps.tariffConstructor.errors && !nextProps.tariffConstructor.errors) {
            this.setState({ disabled: false })
        }

        if (this.state.checkboxOptions.length === 0 && nextProps.tariffConstructor.defaultData.options.length > 0) this.setState({ checkboxOptions: nextProps.tariffConstructor.defaultData.options });

        if (this.props.tariffConstructor.recalcData.options !== nextProps.tariffConstructor.recalcData.options && this.state.checkboxOptions.length > 0) {
            let optionsArrState = [...this.state.checkboxOptions];

            if (nextProps.tariffConstructor.recalcData.options.length > 0) {
                if (JSON.stringify(optionsArrState) !== JSON.stringify(nextProps.tariffConstructor.recalcData.options)) {
                    let optionsArrProps = [...nextProps.tariffConstructor.recalcData.options];
                    for (let param in optionsArrProps) {
                        let itemFind = optionsArrState.find(item => item.type === optionsArrProps[param].type);
                        if (!itemFind) {
                            optionsArrState.push(optionsArrProps[param]);
                        }
                    }
                    if (JSON.stringify(optionsArrState) !== JSON.stringify(this.state.checkboxOptions)) {
                        this.setState({ checkboxOptions: optionsArrState })
                    }
                }
            }
        }

        if (typeof this.props.tariffConstructor.number !== "undefined" &&
            this.props.tariffConstructor.number !== nextProps.tariffConstructor.number && !!this.state.disabledRqs) {
            this.setState({ disabledRqs: false })
        }
    }

    componentWillUnmount() {
        if (this.state.smsParamReq) this.setState({ smsParamReq: false });
    }

    setUrlParams = (urlParams, smsCount) => {
        let location = this.props.location;
        let pathnameRegion = location.pathname.substr(1).split('/');
        let regions = this.props.regions && this.props.regions.list;
        let regionObj = regions.find(x => x.alias === pathnameRegion[0]);
        let validMin = !!urlParams[0] && +urlParams[0] <= this.props.axis.x[this.props.axis.x.length - 1] ? true : false;
        let validGb = !!urlParams[1] && (urlParams[1] === 'u' || urlParams[1] === 'r') || !!urlParams[1] && urlParams[1] !== '0' && +urlParams[1] <= this.props.axis.y[this.props.axis.y.length - 2] ? true : false;

        let newArr = smsCount.filter(x => x.sms_count === +urlParams[2])
        let smsNumber = newArr.length > 0 ? newArr[0].sms_count : null;

        let validUnlimSocial = !!urlParams[3] && +urlParams[3] === 0 || !!urlParams[3] && +urlParams[3] === 1 ? true : false;
        let validUnlimVoice = !!urlParams[4] && +urlParams[4] === 0 || !!urlParams[4] && +urlParams[4] === 1 ? true : false;
        let validYoutube = !!urlParams[5] && +urlParams[5] === 0 || !!urlParams[5] && +urlParams[5] === 1 ? true : false;
        let validIvi = !!urlParams[6] && +urlParams[6] === 0 || !!urlParams[6] && +urlParams[6] === 1 ? true : false;
        let validHome = !!urlParams[7] && +urlParams[7] === 0 || !!urlParams[7] && +urlParams[7] === 1 ? true : false;
        let validAllParamUrl = !!regionObj && validMin && validGb && validUnlimSocial && validUnlimVoice && validYoutube && validIvi && validHome;

        this.setState({ validAllParamUrl })

        const axisY = this.props.axis && this.props.axis.y;
        const axisX = this.props.axis && this.props.axis.x;
        let gb = (urlParams[1] === 'r' || urlParams[1] === 'u') ? 1 : +urlParams[1];

        let sms = (+urlParams[2] === smsNumber) ? 1 : 0;
        let social_unlim = +urlParams[3] === 1 ? 1 : 0;
        let data_unlim = (urlParams[1] === 'u' || urlParams[1] === 'r') ? 1 : 0;
        let motiv_voice_unlim = +urlParams[4] === 1 ? 1 : 0;
        let is_yotube_unlim = +urlParams[5] === 1 ? 1 : 0;
        let is_ivi = +urlParams[6] === 1 ? 1 : 0;
        let home_region_checked = +urlParams[7] === 1 ? 1 : 0;
        let router = urlParams[1] === 'r' ? 1 : 0;
        let axisYVal = axisY.indexOf(gb);
        let axisXVal = axisX.indexOf(+urlParams[0]);
        let smsCountUrl = (+urlParams[2] === +smsNumber) ? +urlParams[2] : 0;

        let data = {
            min: +urlParams[0],
            gb,
            sms,
            social_unlim,
            data_unlim,
            motiv_voice_unlim,
            axisYVal,
            axisXVal,
            router,
            is_yotube_unlim,
            is_ivi,
            home_region_checked,
            smsCountUrl,
        }
        if (!!validAllParamUrl) this.setParamsTariff(data, 'params_data_url');
    }

    combineTariffInfo = (tariffInfo) => {
        const infoData = tariffInfo && tariffInfo.details_message;
        const labelRegion = infoData.find(x => x.type === 'region');
        const abon = infoData.find(x => x.type === 'abon');
        const discount_period = infoData.find(x => x.type === 'discount_period');
        const discount_subscription_fee = infoData.find(x => x.type === 'discount_subscription_fee');

        let info = [
            {
                id: 1,
                label: "Регион подключения",
                value: labelRegion.value,
                additionalClassName: ""
            }];

        if (!!discount_period) {
            info.push(
                {
                    id: 2,
                    label: discount_period.param,
                    value: discount_period.value,
                    additionalClassName: "attention"
                }
            );
        }

        if (!!discount_subscription_fee) {
            info.push(
                {
                    id: 3,
                    label: discount_subscription_fee.param,
                    value: discount_subscription_fee.value,
                    additionalClassName: "attention"
                }
            );
        }

        if (!abon.including) {
            info.push(
                {
                    id: 4,
                    label: abon.param,
                    value: abon.value,
                    additionalClassName: ""
                }
            );
        }

        if (!!abon.including) {
            info.push(
                {
                    id: 5,
                    label: abon.param,
                    value: abon.value,
                    additionalClassName: "info-without-border"
                }
            );

            info.push(
                {
                    id: 6,
                    label: "В том числе:",
                    additionalClassName: "info-without-border"
                }
            );

            abon.including.map((item, id) => {
                return (
                    info.push({
                        id: 7 + id,
                        label: item.param,
                        value: item.value,
                        additionalClassName: "info-without-border"
                    })
                )
            });
        }

        if (!!infoData) {
            infoData.map((item, id) => {
                if (item.type !== 'region' &&
                    item.type !== 'discount_period' &&
                    item.type !== 'discount_subscription_fee' &&
                    item.type !== 'abon') {
                    if (item.value.indexOf('\n') > -1) {
                        const newItems = item.value.replace(/\n/gim, "<br>");
                        return (
                            info.push({
                                id: 15 + id,
                                label: item.param,
                                value: newItems,
                                additionalClassName: ""
                            })
                        )
                    } else if (item.param !== 'Рекомендованный баланс') {
                        return (
                            info.push({
                                id: 16 + id,
                                label: item.param,
                                value: item.value,
                                additionalClassName: ""
                            })
                        )
                    } else {
                        return (
                            info.push({
                                id: 16 + id,
                                label: item.param,
                                value: item.value,
                                additionalClassName: "info-top-border"
                            })
                        )
                    }

                }
            })
        }

        return info;
    };

    // items - сам массив чисел
    // n - число шага (если 20, то 200 - 400)
    getMarks = (items, n, m) => {
        let defObj = this.props.tariffConstructor && this.props.tariffConstructor.defaultData;
        let maxNumberGb = defObj && defObj.scope && (+defObj.scope.data_max / 10);

        if (!!items) {
            const result = [items[0]];

            // for (let i = m || n; i <= 7 * n; i += n) {
            for (let i = m || n; i <= maxNumberGb * n; i += n) {
                result.push(items[i])
            }
            result.push(items[items.length - 1])

            return result
        }
    }


    getObjectMarks = (type, arr) => {
        let defObj = this.props.tariffConstructor && this.props.tariffConstructor.defaultData;
        let unlim = this.state.data_unlim_default

        let maxNumberGb = defObj && defObj.scope && (+defObj.scope.data_max / 10);

        let marksObject = {};
        let newArr = [];

        if (type === 'gb' && arr || type === 'minutes' && arr && arr.length <= 7) {
            newArr = [...arr];
        }

        if (type === 'gb' && arr) {
            for (let i = 0; i < maxNumberGb.length; i++) {
                newArr.push(arr[i]);
            }
            newArr.push(arr[arr.length - 2]);
            newArr.push(arr[arr.length - 1]);
        }

        if (type === 'minutes' && arr && arr.length > 7) {
            for (let i = 0; i < 6; i++) {
                newArr.push(arr[i]);
            }
            newArr.push(arr[arr.length - 1]);
        }

        for (let i = 0; i < newArr.length; i++) {
            if (type === 'gb') {
                if (newArr[i] !== 0) {
                    marksObject[newArr[i]] = newArr[i];
                } else if (unlim) {
                    let maxVal = this.maxValueObj(marksObject);
                    marksObject[parseInt(maxVal) + 10] = '∞';
                }
            }
            if (type === 'minutes') {
                marksObject[newArr[i]] = newArr[i];
            }
        }
        return marksObject;
    };


    maxValueObj = (obj) => {
        return Object.entries(obj).reduce((max, n) => n[1] > max[1] ? n : max)[1]
    }

    onCheckboxChange = (name) => {
        this.setState({
            flag: 0
        }, () => {
            this.onParamChange(name, this.state[name] === 0 ? 1 : 0);
        });
        this.setState({ disabledRqs: true });
    };

    offCheckboxChange = (name) => {
        this.setState({
            flag: 0
        }, () => {
            this.onParamChange(name, 0);
        });
    };

    onParamChange = (name, val) => {
        this.setState((prevState) => ({
            [name]: val, ...(name === 'data_unlim' && !val)
                ? { router: 0, axisYVal: prevState.axisYVal !== -1 ? prevState.axisYVal : 0 }
                : {}
        }), () => {
            this.recountPrice();
        })
    };

    checkboxClick = () => {
        if (this.state.data_unlim === 1) {
            if (this.props.isGuest || !this.props.isGuest && this.props.currentTariff && this.props.currentTariff.slug.indexOf('svoi') === -1) {
                if (this.state.axisYVal > this.props.axis.y.length - 1) {
                    this.setState({
                        axisYVal: this.props.axis.y.indexOf(+this.state.gb_default),
                        valueSvoiGb: +this.state.gb_default
                    })
                }
            };

            if (!this.props.isGuest && this.props.currentTariff && this.props.currentTariff.slug.indexOf('svoi') !== -1) {
                if (this.state.axisYVal > this.props.axis.y.length - 1) {
                    if (this.props.currentTariff.details.traffic !== '') {
                        this.setState({
                            axisYVal: this.props.axis.y.indexOf(parseInt(this.props.currentTariff.details.traffic)),
                            valueSvoiGb: parseInt(this.props.currentTariff.details.traffic)
                        })
                    } else {
                        this.setState({
                            axisYVal: this.props.axis.y.indexOf(1),
                            valueSvoiGb: 1
                        })
                    }
                }
            }
        }
    }

    // убираем тултип по клику вне него "пицеман"
    handleClickOutside = (event) => {
        const path = event.path || (event.composedPath && event.composedPath());
        // includes --- ищет указанный элемет в массиве, возвращает true или false
        if (!path.includes(this.tooltipRef.current)) {
            this.setState({
                tooltipOn: false,
            });
        }
    };

    // изменяет значение ползунка
    onChangeRange = (param) => {
        let {
            tariffConstructor,
            axis
        } = this.props;

        let constructorDataCostType = tariffConstructor && tariffConstructor.recalcData && tariffConstructor.recalcData.constructor && tariffConstructor.recalcData.constructor.cost_type;

        if (param === 'axisYVal') {
            return (val) => {
                if (parseInt(val) > this.props.axis.y.length - 1) {
                    this.state[param] = this.props.axis.y.length + 9;
                    this.setState({ [param]: this.props.axis.y.length + 9 });
                } else {
                    if (val === '' && !!this.state.data_unlim) {
                        this.onCheckboxChange('data_unlim');
                    }
                    if (parseInt(val) < this.props.axis.y[0] && this.state.axisYVal === -1) {
                        this.state[param] = 0;
                        this.setState({ [param]: 0 });
                    } else {
                        this.state[param] = this.props.axis.y.indexOf(parseInt(val));
                        this.setState({ [param]: this.props.axis.y.indexOf(parseInt(val)) });
                    }
                }

                if (parseInt(val) <= this.props.axis.y.length - 1 && this.props.axis.y[this.state.axisYVal] !== this.state.valueSvoiGb) {
                    this.setState({
                        valueSvoiGb: this.props.axis.y[this.state.axisYVal]
                    });

                    if (this.state.data_unlim) {
                        this.setState({
                            data_unlim: 0
                        });
                    }
                }

                if (parseInt(val) > this.props.axis.y.length - 1 && parseInt(val) !== this.state.valueSvoiGb) {
                    this.setState({
                        valueSvoiGb: this.state.maxValInternet
                    })

                    if (!this.state.data_unlim) {
                        this.onCheckboxChange('data_unlim');
                    }
                }

                // при фиксировании цены получаем нужный объект с нужным значением и синхранизируем ползунки согласно полученному обекту
                if (constructorDataCostType === 1) {
                    let allArrMarks = tariffConstructor && tariffConstructor.recalcData && tariffConstructor.recalcData.scope && tariffConstructor.recalcData.scope.allowed_values;

                    // если получаем 0 в gb, то заменяем его на макс значение +10
                    let replaceZeroGb = allArrMarks && allArrMarks.map((i) => {
                        if (i.gb === 0) {
                            return { gb: this.state.fixMaxValInternet, min: i.min }
                        }
                        return i
                    })

                    // // если получаем параметры равные val, то включаем unlim
                    // if (val === this.state.fixMaxValInternet && this.state.fixMaxValInternet > 70 && !this.state.data_unlim) {
                    //     this.setState({
                    //         // data_unlim: 1
                    //     }, () => {
                    //         // this.onCheckboxChange('data_unlim');
                    //     });
                    // }

                    let finedObj = replaceZeroGb && replaceZeroGb.find((x) => x.gb === val);
                    this.setState({
                        valueMinutes: finedObj && finedObj.min,
                        axisXVal: axis && axis.x.indexOf(finedObj && finedObj.min),
                    })
                }
            }
        }

        if (param === 'axisXVal') {
            return (val) => {
                this.state[param] = this.props.axis.x.indexOf(parseInt(val));
                this.setState({ [param]: this.props.axis.x.indexOf(parseInt(val)) });

                if (val !== this.state.valueMinutes) {
                    this.setState({
                        valueMinutes: val
                    })
                }

                // при фиксировании цены получаем нужный объект с нужным значением и синхранизируем ползунки согласно полученному обекту
                if (constructorDataCostType === 1) {
                    let allArrMarks = tariffConstructor && tariffConstructor.recalcData && tariffConstructor.recalcData.scope && tariffConstructor.recalcData.scope.allowed_values;
                    // если получаем 0 в gb, то заменяем его на макс значение +10
                    let replaceZeroGb = allArrMarks && allArrMarks.map((i) => {
                        if (i.gb === 0) {
                            return { gb: this.state.fixMaxValInternet, min: i.min }
                        }
                        return i
                    });

                    if (val === this.state.fixMinValMinutes && this.state.fixMaxValInternet > 90 && !this.state.data_unlim) {
                        // if (val === this.state.fixMinValMinutes && this.state.fixMaxValInternet > 70) {
                        this.setState({
                            // data_unlim: 1
                        }, () => {
                            this.onCheckboxChange('data_unlim');
                        });
                    }

                    if (val !== this.state.fixMinValMinutes && this.state.fixMaxValInternet > 90 && !this.state.maxFixedPrice) {
                        if (this.state.data_unlim) {
                            this.setState({
                                data_unlim: 0
                            });
                        }
                    }
                    let finedObj = replaceZeroGb && replaceZeroGb.find((x) => x.min === val);
                    this.setState({
                        valueSvoiGb: finedObj && finedObj.gb,
                        axisYVal: axis && axis.y.indexOf(finedObj && finedObj.gb),
                    })
                }
            }
        }
    };

    // для ползунка
    getRange = (object, item, roundingLow, roundingHigh) => {
        let {
            tariffConstructor,
        } = this.props;

        const keys = Object.keys(object);
        let arrRange = [];
        let range = {};
        let result = null;

        let constructorDataCostType = tariffConstructor && tariffConstructor.recalcData && tariffConstructor.recalcData.constructor && tariffConstructor.recalcData.constructor.cost_type;

        for (let i = 0; i < keys.length - 1; i++) {
            arrRange.push({ min: object[keys[i]], max: object[keys[i + 1]] })
        }

        for (let j = 0; j < arrRange.length; j++) {

            if (item >= arrRange[j].min && item <= arrRange[j].max || item >= arrRange[j].min && isNaN(arrRange[j].max)) {
                range = (arrRange[j]);
            }
        }

        if (!!range) {
            let roundLow = getRounding(range.min, !isNaN(range.max) ? range.max : range.min + 10, parseInt(roundingLow));
            let roundHigh = getRounding(range.min, !isNaN(range.max) ? range.max : range.min + 10, parseInt(roundingHigh));

            if (roundingLow === 0 && roundingHigh === 0 || item > roundLow && item < range.max && roundingHigh === 0 || item > roundLow && item < roundHigh) {
                result = item
            } else if (item <= roundLow && !isNaN(range.max) || item <= roundLow && isNaN(range.max)) {
                // при фиксации цены и перемещении ползунков, возвращает ползунки на начальное значение приходящее с апи
                if ((constructorDataCostType !== 1)) {
                    // нужен для расчетa цены при выборе 0 минут
                    result = range.min
                }
            } else if (item >= roundHigh) {
                result = range.max
            }

            // if (isNaN(range.max) && (item > roundLow && item < roundHigh || item === roundLow || item >= roundHigh)) {
            //     result = range.min + 10
            // }
        }

        return result
    }

    // для ползунка
    onAfterChangeSlider = (type, objectMarks, items, roundingLow, roundingHigh) => {
        let result = null;
        const axis = this.props.axis && this.props.axis;
        let axisYVal = this.state.axisYVal;

        let constructor = this.props.tariffConstructor && this.props.tariffConstructor.recalcData && this.props.tariffConstructor.recalcData.constructor;
        let dataUnlim = chekParams(constructor && constructor.options, 'DATA_UNLIM')

        roundingLow = roundingLow === '' ? 0 : parseInt(roundingLow);
        roundingHigh = roundingHigh === '' ? 0 : parseInt(roundingHigh);

        if (objectMarks && items >= 0) {
            result = this.getRange(objectMarks, items, roundingLow, roundingHigh);
            if (type === 'svoiGb') {
                // if (!!result) {
                if (!!result && !this.state.cost_type) {
                    this.setState({
                        axisYVal: axis.y.indexOf(result),
                        valueSvoiGb: result
                    })

                    if (axisYVal > this.props.axis.y.length - 1) {
                        this.onParamChange('axisYVal', this.props.axis.y.length - 1);
                    } else {
                        this.onParamChange('axisYVal', axis.y.indexOf(result));
                    }
                }

                // перезапрашиваем апи при оключении unlim
                if (!this.state.data_unlim && dataUnlim) {
                    this.offCheckboxChange('data_unlim');
                    if (this.state.router) {
                        this.offCheckboxChange('router');
                    }
                }
            }
            if (type === 'minutes') {
                // if (result !== null) {
                if (result !== null && !this.state.cost_type) {
                    this.setState({
                        axisXVal: axis.x.indexOf(result),
                        valueMinutes: result
                    })

                    this.onParamChange('axisXVal', axis.x.indexOf(result));
                }

                // перезапрашиваем апи при оключении unlim
                if (!this.state.data_unlim && dataUnlim) {
                    this.offCheckboxChange('data_unlim');
                    if (this.state.router) {
                        this.offCheckboxChange('router');
                    }
                }
            }
        }
    }

    recountSliders = (name) => {
        if (name === 'svoiGb') {
            this.setState({
                focusSvoiGb: false
            });
            if (this.state.valueSvoiGbOld !== this.state.valueSvoiGb) {
                this.setState((prevState) => {
                    return { valueSvoiGbOld: prevState.valueSvoiGb }
                });
                this.recountPrice()
            }

            // if (this.state.axisYVal === -1) {
            if (this.state.axisYVal === -1 || this.state.axisYVal === 90) {
                this.setState({
                    axisYVal: 0,
                    valueSvoiGb: 0
                }, () => {
                    this.recountPrice()
                });
            }
        }
        if (name === 'minutes') {
            this.setState({
                focusMinutes: false
            })
            if (this.state.axisXVal === -1) {
                let axisXVal = this.getClosest(this.props.axis.x, this.state.valueMinutes);
                this.setState({
                    axisXVal,
                    valueMinutes: this.props.axis.x[axisXVal]
                }, () => {
                    if (this.state.valueMinutesOld !== this.state.valueMinutes) {
                        this.setState((prevState) => {
                            return { valueMinutesOld: prevState.valueMinutes }
                        });
                        this.recountPrice()
                    }
                })
            } else if (this.state.valueMinutesOld !== this.state.valueMinutes) {
                this.setState((prevState) => {
                    return { valueMinutesOld: prevState.valueMinutes }
                });
                this.recountPrice()
            }
        }
    }

    getClosest = (axis, val) => {
        let i = 0;
        while (i < axis.length && axis[i] < val) {
            i++;
        }
        return Math.min(i, axis.length - 1);
    }

    recountPrice = () => {
        let {
            axisXVal,
            axisYVal,
            sms,
            social_unlim,
            data_unlim,
            motiv_voice_unlim,
            router,
            youtube_unlim,
            ivi,
            home_region_checked,

            currentSmsPack,
            cost_type,
            user_cost,
            first_in
        } = this.state;

        const axis = this.props.axis;

        if (axisYVal >= axis.y.length || data_unlim === 1) {
            axisYVal = axis.y.length - 1
        }

        this.props.getPrice(axis.x[axisXVal], axis.y[axisYVal], sms, social_unlim, data_unlim, motiv_voice_unlim, router, youtube_unlim, ivi, currentSmsPack, cost_type, user_cost, home_region_checked, first_in);
    }

    setParamsTariff = (data, type) => {
        if (!!data) {
            const axis = this.props.axis;
            let min, gb, sms, social_unlim, data_unlim, motiv_voice_unlim, axisYVal, axisXVal, router, youtube_unlim, ivi, home_region_checked, currentSmsPack, cost_type, user_cost, first_in;

            if (type === 'default_setting') {
                min = data.constructor.min;
                gb = data.constructor.data;
                sms = this.state.currentSmsPack === 'SMS_PACK_0' ? 0 : 1;

                data_unlim = chekParams(data.constructor.options, 'DATA_UNLIM') ? 1 : 0;
                // data_unlim = this.state.data_unlim_default;
                router = this.state.router_default;
                social_unlim = this.state.social_unlim_default;
                motiv_voice_unlim = this.state.motiv_voice_unlim_default;
                youtube_unlim = this.state.youtube_unlim_default,
                    ivi = this.state.ivi_default,
                    home_region_checked = this.state.home_region_checked_default,

                    axisYVal = axis && axis.y.indexOf(+data.constructor.data);
                axisXVal = axis && axis.x.indexOf(+data.constructor.min);
                currentSmsPack = this.state.currentSmsPack;

                cost_type = this.state.cost_type;
                user_cost = data.constructor.user_cost ? data.constructor.user_cost : this.state.user_cost;
                first_in = this.state.first_in;
            } else if (type === 'params_data_url') {
                min = data.min;
                gb = data.gb;
                sms = data.sms;

                data_unlim = data.data_unlim;
                router = data.router;
                social_unlim = data.social_unlim;
                motiv_voice_unlim = data.motiv_voice_unlim;
                youtube_unlim = data.is_yotube_unlim;
                ivi = data.is_ivi;
                home_region_checked = data.home_region_checked;

                axisYVal = data.axisYVal;
                axisXVal = data.axisXVal;
                currentSmsPack = `SMS_PACK_${data.smsCountUrl}`

                cost_type = this.state.cost_type
                user_cost = data.constructor.user_cost ? data.constructor.user_cost : this.state.user_cost
            } else if (type === 'default_auth') {
                min = data.constructor.min;
                gb = data.constructor.data;

                data_unlim = chekParams(data.constructor.options, 'DATA_UNLIM') ? 1 : 0;
                router = chekParams(data.constructor.options, 'ROUTER') ? 1 : 0;
                social_unlim = chekParams(data.constructor.options, 'SOCIAL_UNLIM') ? 1 : 0;
                motiv_voice_unlim = chekParams(data.constructor.options, 'MVOICE_UNLIM') ? 1 : 0;
                youtube_unlim = chekParams(data.constructor.options, 'YOUTUBE_UNLIM') ? 1 : 0;
                ivi = chekParams(data.constructor.options, 'IVI') ? 1 : 0;
                home_region_checked = chekParams(data.constructor.options, 'HOME_REGION_CHECKED') ? 1 : 0;

                axisYVal = axis && axis.y.indexOf(+data.constructor.data);
                axisXVal = axis && axis.x.indexOf(+data.constructor.min);
                currentSmsPack = data.constructor.sms_count ? `SMS_PACK_${data.constructor.sms_count}` : this.state.currentSmsPack;

                cost_type = this.state.cost_type;
                user_cost = data.constructor.user_cost ? data.constructor.user_cost : this.state.user_cost;
                first_in = this.state.first_in;
            } else {
                min = !!data.details.minutes ? parseInt(data.details.minutes) : 0;
                gb = data.details.is_data_unlim ? axis.y[axis.y.length - 1] : !!data.details.traffic ? parseInt(data.details.traffic) : 0;
                sms = !!data.details.sms ? 1 : 0;

                data_unlim = data.details.is_data_unlim ? 1 : 0;
                router = data.details.router ? 1 : 0;
                social_unlim = data.details.is_socnet_unlim ? 1 : 0;
                youtube_unlim = data.details.is_yotube_unlim ? 1 : 0;
                motiv_voice_unlim = !!data.details.is_motiv_voice_unlim ? 1 : 0;
                ivi = data.details.is_ivi ? 1 : 0;
                home_region_checked = data.details.home_region_checked ? 1 : 0;

                axisYVal = data.details.is_data_unlim ? axis.y.indexOf(axis.y[axis.y.length - 1]) : axis.y.indexOf(gb);
                axisXVal = axis && axis.x.indexOf(min);
                // currentSmsPack = `SMS_PACK_${0}`
                // currentSmsPack = ''
                currentSmsPack = `SMS_PACK_${+data.details.sms}`

                cost_type = this.state.cost_type
                user_cost = data.constructor.user_cost ? data.constructor.user_cost : this.state.user_cost
            }

            this.setState({
                axisYVal,
                axisXVal,
                sms,
                social_unlim,
                data_unlim,
                motiv_voice_unlim,
                router,
                youtube_unlim,
                ivi,
                home_region_checked,
                currentSmsPack,

                cost_type,
                user_cost,
                first_in
            })

            this.props.getPrice(min, gb, sms, social_unlim, data_unlim, motiv_voice_unlim, router, youtube_unlim, ivi, currentSmsPack, cost_type, user_cost, home_region_checked, first_in);
        }
    }

    onFocus = (name) => {
        return () => {
            if (name === 'svoiGb' && !this.state.focusSvoiGb) {
                this.setState({
                    focusSvoiGb: true
                })
            }
            if (name === 'minutes' && !this.state.focusMinutes) {
                this.setState({
                    focusMinutes: true
                })
            }
        }
    }

    onBlur = (name) => {
        return () => {
            this.recountSliders(name);
        }
    }

    onKeyDown = (e, type, maxVal = false) => {
        if (e.keyCode === 13) {
            this.recountSliders(type);
        }
    }

    onBlurName = (e) => {
        this.setState({ focus: false });
        if (e.target.value) {
            this.props.checkWord(e.target.value)
        }
    }
    onFocusName = (e) => {
        this.setState({ focus: true });
    }

    openingConnectionForm = (checkBalance) => {
        this.recountPrice();
        if (!checkBalance) this.props.onClickConnect('tariff-connection')
        else this.props.onClickConnect('tariff-connection-check')
    }

    setSearchParams = (constrUrl) => {
        if (window.history.replaceState) {
            window.history.replaceState(null, '', constrUrl);
        }
    }

    checkParam = (arr, type, findItem) => {
        if (arr.length > 0 && arr.find(l => l[type] === findItem)) {
            return true
        }
        return false
    }

    setChekParam = arr => {
        const {
            data_unlim,
            router,
            social_unlim,
            motiv_voice_unlim,
            sms,
            smsChOn,
            youtube_unlim,
            ivi,

            dataChOn,
            routerChOn,
            socialChOn,
            voiceChOn,
            ytbChOn,
            iviChOn,
            homeChOn,

            currentSmsPack
        } = this.state;

        let ytbProps = this.checkParam(arr, 'type', 'YOUTUBE_UNLIM');
        let dataProps = this.checkParam(arr, 'type', 'DATA_UNLIM');
        let routerProps = this.checkParam(arr, 'type', 'ROUTER');
        let socialProps = this.checkParam(arr, 'type', 'SOCIAL_UNLIM');
        let voiceProps = this.checkParam(arr, 'type', 'MVOICE_UNLIM');
        let ivivProps = this.checkParam(arr, 'type', 'IVI');
        let homeProps = this.checkParam(arr, 'type', 'HOME_REGION_CHECKED');

        if (!dataProps && !!dataChOn) {
            this.setState({ dataChOn: false });
            this.offCheckboxChange('data_unlim');
        }
        if (!!dataProps && !dataChOn) this.setState({ dataChOn: true });

        if (!routerProps && !!routerChOn) {
            this.setState({ routerChOn: false });
            this.offCheckboxChange('router');
        }
        if (!!routerProps && !routerChOn) this.setState({ routerChOn: true });

        if (!socialProps && !!socialChOn) {
            this.setState({ socialChOn: false });
            this.offCheckboxChange('social_unlim');
        }
        if (!!socialProps && !socialChOn) this.setState({ socialChOn: true });

        if (!voiceProps && !!voiceChOn) {
            this.setState({ voiceChOn: false });
            this.offCheckboxChange('motiv_voice_unlim');
        }
        if (!!voiceProps && !voiceChOn) this.setState({ voiceChOn: true });

        if (!ytbProps && !!ytbChOn) {
            this.setState({ ytbChOn: false });
            this.offCheckboxChange('youtube_unlim');
        }
        if (!!ytbProps && !ytbChOn) this.setState({ ytbChOn: true });

        if (!ivivProps && !!iviChOn) {
            this.setState({ iviChOn: false });
            this.offCheckboxChange('ivi');
        }
        if (!!ivivProps && !iviChOn) this.setState({ iviChOn: true });

        if (!homeProps && !!homeChOn) {
            this.setState({ homeChOn: false });
            this.offCheckboxChange('home_region_checked');
        }
        if (!!homeProps && !homeChOn) this.setState({ homeChOn: true });
    }

    // кнопка фиксирования цены
    onToggleFixedPrice = () => {
        this.setState((prevState) => ({
            cost_type: prevState.cost_type === 0 ? 1 : 0
        }), () => {
            this.recountPrice();
        })
    }

    // изменение цены
    onChangeUserPrice = (event) => {
        // не даем возможность вводить буквы
        let value = event.target.value;
        event.target.value = value.replace(/\D/g, '')

        this.setState({
            user_cost: event.target.value
        });
    }

    // меняем радио кнопки смс
    toggleSmsPack = (type) => {
        // калбек используется для асинхронного выполнения кода 
        this.setState({
            currentSmsPack: type === this.state.currentSmsPack ? '' : type
        }, () => {
            this.recountPrice();
        });

        const getNum = parseInt(type.match(/\d+/));
        this.props.setSmsCount(+getNum);
    }

    // отображает или убирает тултип под первым инпутом 
    checkAvailableField = () => {
        let {
            tariffConstructor
        } = this.props;

        let offerOptions = tariffConstructor && tariffConstructor.recalcData && tariffConstructor.recalcData.offer_options;

        // Если ID таймера установлено - сбрасываем таймер
        if (this.timeoutRef.current) {
            clearTimeout(this.timeoutRef.current);
        }

        if (offerOptions) {
            this.setState({
                tooltipOn: true,
                tooltipValue: offerOptions.text
            });

            // Запускаем таймер, возвращаемое ID таймера
            // записываем в timeoutRef
            this.timeoutRef.current = setTimeout(() => {
                this.setState({
                    tooltipOn: false,
                    tooltipValue: ''
                });
            }, 10000);
        }
    }

    // кнопка всплывающего тултипа, при нажатии подтверждаем и изменяем цену в инпуте
    onTooltipClick = () => {
        let {
            tariffConstructor
        } = this.props;

        let options = tariffConstructor && tariffConstructor.recalcData && tariffConstructor.recalcData.offer_options && tariffConstructor.recalcData.offer_options.options[0];
        let surchargePrice = tariffConstructor && tariffConstructor.recalcData && tariffConstructor.recalcData.offer_options && tariffConstructor.recalcData.offer_options.surcharge_price;

        this.setState((prevState) => ({
            user_cost: prevState.user_cost + surchargePrice
        }), () => {
            this.recountPrice();
        })

        if (options && options === 'SOCIAL_UNLIM') {
            this.onCheckboxChange('social_unlim');
        }
        if (options && options === 'MVOICE_UNLIM') {
            this.onCheckboxChange('motiv_voice_unlim');
        }
        this.setState({ tooltipOn: false });
    }

    // заменяем метки и поля слайдера при фиксировании цены
    replaceMarksForInputRange = () => {
        let {
            tariffConstructor
        } = this.props;

        let allGrid = tariffConstructor && tariffConstructor.recalcData && tariffConstructor.recalcData.scope && tariffConstructor.recalcData.scope.allowed_values;
        let newMinGrid = allGrid && allGrid.map((item) => (
            item.min
        ));
        let newGbGrid = allGrid && allGrid.map((item) => (
            item.gb
        ));

        let sortNewGbGrid = newGbGrid && newGbGrid.sort((a, b) => a - b);
        let maxGbNumber = sortNewGbGrid && sortNewGbGrid[sortNewGbGrid.length - 1]

        let maxGB = 0, minGB = 0, maxMin = 0, minMin = 0;

        // записываем одинаковые ключ - значение (10: 10)
        let minObj = newMinGrid && newMinGrid.reduce((a, i) => (a[i] = i, a), {});
        let gbObj = newGbGrid && newGbGrid.reduce((a, i) => (a[i] = i, a), {});

        // при получении только максимального значения минут
        if (Object.keys(minObj) && Object.keys(minObj).length === 1) {
            if (newMinGrid.some((i) => i === 0)) {
                minObj = {
                    ...minObj,
                }
                maxMin = newMinGrid[newMinGrid.length + 1];
            } else {
                minObj = {
                    ...minObj,
                }
                maxMin = newMinGrid[0];
            }
            // minObj = {
            //     // [0]: 0,
            //     ...minObj,
            // }
            // maxMin = newMinGrid[0];

            // this.setState({
            //     maxFixedPrice: true
            // });
        } else {
            minObj = {
                ...minObj,
            }

            minMin = newMinGrid[newMinGrid.length - 1];
            maxMin = newMinGrid[0];

            this.setState({
                maxFixedPrice: false
            });
        }

        // если есть 0
        if (newGbGrid.some((i) => i === 0)) {
            if (maxGbNumber === 0) {
                // при получении только максимального значения гб
                if (Object.keys(gbObj) && Object.keys(gbObj).length === 1) {
                    gbObj = {
                        [75]: '∞'
                    }

                    maxGB = 75;

                    this.setState({
                        maxFixedPrice: true
                    });
                } else {
                    gbObj = {
                        ...gbObj,
                        [`${maxGbNumber}`]: '∞'
                    }

                    maxGB = 75;
                }
            } else {
                delete gbObj[0];
                gbObj = {
                    ...gbObj,
                    [`${maxGbNumber + 30}`]: '∞'
                }

                maxGB = maxGbNumber + 30;
                minGB = sortNewGbGrid[1];
            }
        } else {
            if (Object.keys(minObj) && Object.keys(minObj).length === 1) {
                gbObj = {
                    // [0]: 0,
                    ...gbObj,
                }
                // minMin = 0;
                maxGB = newGbGrid[0];
            } else {
                maxGB = maxGbNumber;
                minGB = sortNewGbGrid[0];
            }
        }

        this.setState({
            fixMarksObjectMinutes: minObj,
            fixMinValMinutes: minMin,
            fixMaxValMinutes: maxMin,
            fixMarksObjectGb: gbObj,
            fixMinValInternet: minGB,
            fixMaxValInternet: maxGB,
            isZero: newGbGrid.some((i) => i === 0)
        });
    }

    hideScroll = () => {
        let scrollTop = 0;
        if (typeof document != "undefined") {
            document.body.classList.add("no-scroll");
            scrollTop = window.pageYOffset;
            document.body.style.position = "fixed";
            document.body.style.top = -scrollTop + 'px';
        }
    };

    showScroll = () => {
        if (typeof document != "undefined") {
            document.body.classList.remove('no-scroll');
            document.body.style.position = '';
            document.body.style.top = '';
            window.scroll(0, this.scrollTop);
        }
    };

    openModal = () => {
        this.setState(() => {
            return {
                modal: 'cost-type-unlim'
            }
        });
        this.hideScroll();
    };

    closeModal = (e) => {
        if (e) e.preventDefault();
        this.setState({
            modal: '',
            data_unlim: 0
        });
        this.showScroll();
    };

    agreeForUnlim = () => {
        this.setState({
            cost_type: 0,
            modal: '',
            data_unlim: 0,
        }, () => {
            this.onCheckboxChange('data_unlim');
        });
        this.showScroll();
    }

    renderModal() {
        let {
            tariffConstructor
        } = this.props;

        let options = tariffConstructor && tariffConstructor.recalcData && tariffConstructor.recalcData.options;
        let option = options && options.find(i => i.type === 'DATA_UNLIM');
        let text = option && option.notification;

        switch (this.state.modal) {
            case "cost-type-unlim":
                return <InfoModalUnlim
                    close={this.closeModal}
                    agreeForUnlim={this.agreeForUnlim}
                    text={text}
                />;
        }
    }

    // в автор зоне вставляем смски согласно пакетам в инфе
    // если совпадают, то нужный блок смс, если нет то ничего не выставляем
    clientSmsCount = (arr, count) => {
        let obj = arr.some((i) => i.sms_count === count);
        if (obj && count !== 0) {
            this.setState({
                currentSmsPack: `SMS_PACK_${count}`,
            });
        } else {
            this.setState({
                currentSmsPack: '',
            });
        }
    }

    render() {
        let {
            inputTitle,
            focus,
            svoiGb_step,
            axisYVal,
            axisXVal,
            minutes_step,
            tariffInfo,
            valueSvoiGb,
            valueMinutes,
            marksObjectGb,
            marksObjectMinutes,
            sms,
            flag,
            maxValInternet,
            maxValMinutes,
            disabled,
            validAllParamUrl,
            checkboxOptions,

            router,
            data_unlim,
            social_unlim,
            motiv_voice_unlim,
            youtube_unlim,
            ivi,
            home_region_checked,

            dataChOn,
            routerChOn,
            socialChOn,
            voiceChOn,
            ytbChOn,
            iviChOn,
            homeChOn,
            disabledRqs,
            smsChOn,

            optionOtherArr,
            isSm,
            tooltipOn,
            tooltipValue,
            priceMinMax,
            priceMinMaxValue,
            maxFixedPrice,
            user_cost,
            optionSmsArr,
            currentSmsPack,
            urlLink,
            cost_type,
            fixMarksObjectMinutes,
            fixMarksObjectGb,
            fixMaxValMinutes,
            fixMinValMinutes,
            fixMaxValInternet,
            fixMinValInternet,
            isZero
        } = this.state
        let {
            tariffConstructor,
            axis,
            price,
            settings,
            url,
            constructorParams,
            name,
            placeholder,
            defaultParams,
            inputTariffNameRef,
            tariffConnectionValue,
        } = this.props

        // let sms_pack_count = this.props.smsCount;
        // let sms_pack_count = parseInt(currentSmsPack.match(/\d+/));
        let sms_pack_count = parseInt((currentSmsPack === '' ? 'SMS_PACK_0' : currentSmsPack).match(/\d+/));

        const yLength = axis && axis.y && axis.y.length;
        const axisY = axis && axis.y;
        const axisX = axis && axis.x;

        let dataForUrl = {
            min: axisX[axisXVal],
            gb: !!axisY[axisYVal] ? axisY[axisYVal] : 'u',
            data: data_unlim,
            router: router,
            social: social_unlim,
            // sms: sms,
            voice: motiv_voice_unlim,
            sms_pack_count,
            youtube_unlim,
            ivi,
            home_region_checked
        }

        let errorMsg = tariffConstructor.errors && tariffConstructor.errors.message;
        let constrUrl = '', gbUrl = '', constrUrlLink = '';

        gbUrl = dataForUrl.data === 1 && dataForUrl.router === 0 ? 'u' : dataForUrl.data === 1 && dataForUrl.router === 1 ? 'r' : dataForUrl.gb;
        constrUrl = `?svoi=${dataForUrl.min}-${gbUrl}-${dataForUrl.sms_pack_count ? dataForUrl.sms_pack_count : 0}-${dataForUrl.social}-${dataForUrl.voice}-${dataForUrl.youtube_unlim}-${dataForUrl.ivi}-${dataForUrl.home_region_checked}`;
        constrUrlLink = `&svoi=${dataForUrl.min}-${gbUrl}-${dataForUrl.sms_pack_count ? dataForUrl.sms_pack_count : 0}-${dataForUrl.social}-${dataForUrl.voice}-${dataForUrl.youtube_unlim}-${dataForUrl.ivi}-${dataForUrl.home_region_checked}`;

        if (typeof window !== 'undefined' && !!validAllParamUrl) {
            if (window.location.search !== '' && window.location.search !== constrUrl) {
                if (window.location.search.indexOf('&') !== -1) {
                    constrUrl += window.location.search.substr(window.location.search.indexOf('&'))
                }
                this.setSearchParams(constrUrl);
            }
        }

        let dataUnlimLabel = '', routerLabel = '', socialUnlimLabel = '', mVoiceUnlimLabel = '', smsPackLabel = '', youtubeUnlimLabel = '', iviLabel = '', arrDisplayedOptions = [];

        if (defaultParams) {
            arrDisplayedOptions = tariffConstructor.defaultData && tariffConstructor.defaultData.options;
        } else {
            arrDisplayedOptions = tariffConstructor.recalcData && tariffConstructor.recalcData.options;
        }

        if (typeof window !== "undefined") {
            if (arrDisplayedOptions.length > 0) {
                this.setChekParam(arrDisplayedOptions);
            }
        }

        let chOn = {
            dataChOn,
            routerChOn,
            socialChOn,
            voiceChOn,
            smsChOn,
            ytbChOn,
            iviChOn,
            homeChOn
        };

        let recObj = tariffConstructor && tariffConstructor.recalcData;
        let recalcConstructorData = tariffConstructor && tariffConstructor.recalcData && tariffConstructor.recalcData.constructor;

        let tariffConnectionArr = [
            { value: 'Сменить тариф', type: 'quick' },
            { value: 'Перейти в Мотив со своим номером', type: 'mnp' },
            { value: 'Купить сим-карту', type: 'buy_sim_card' },
        ];

        let dataSliderParams = {
            valueMinutes: valueMinutes,
            fixMinValMinutes: fixMinValMinutes,
            valueSvoiGb: valueSvoiGb,
            cost_type: cost_type,
            data_unlim: data_unlim
        }

        let allOptions = tariffConstructor && tariffConstructor.recalcData && tariffConstructor.recalcData.options;
        let requiredOption = allOptions && allOptions.find(i => i.type === 'DATA_UNLIM');
        let optionText = requiredOption && requiredOption.notification;

        return (
            <div className="tariffs__block">
                <div className="tariffs__block_left">
                    <div className="tariffs__block_choice_wrapper">
                        {tariffConnectionArr.map((i) => (
                            <React.Fragment>
                                <div
                                    key={i.value}
                                    className={`tariffs__choice ${tariffConnectionValue === i.type ? "active" : ''}`}
                                    onClick={() => this.props.tariffConnectionToggleAction(i.type)}
                                >
                                    <div>
                                        {i.value}
                                    </div>
                                    <div className={`tariffs__choice_big_circle ${tariffConnectionValue === i.type ? "active" : ''}`}>
                                        <div className={`tariffs__choice_small_circle ${tariffConnectionValue === i.type ? "active" : ''}`} />
                                    </div>
                                </div>
                                {/* {i.type !== 'buy_sim_card'
                                    ? <div
                                        key={i.value}
                                        className={`tariffs__choice ${tariffConnectionValue === i.type ? "active" : ''}`}
                                        onClick={() => this.props.tariffConnectionToggleAction(i.type)}
                                    >
                                        <div>
                                            {i.value}
                                        </div>
                                        <div className={`tariffs__choice_big_circle ${tariffConnectionValue === i.type ? "active" : ''}`}>
                                            <div className={`tariffs__choice_small_circle ${tariffConnectionValue === i.type ? "active" : ''}`} />
                                        </div>
                                    </div>
                                    : <a
                                        key={i.value}
                                        className={`tariffs__choice ${tariffConnectionValue === i.type ? "active" : ''}`}
                                        onClick={() => this.props.tariffConnectionToggleAction(i.type)}
                                        href={urlLink}
                                        target="_blank"
                                    >
                                        <div>
                                            {i.value}
                                        </div>
                                        <div className={`tariffs__choice_big_circle ${tariffConnectionValue === i.type ? "active" : ''}`}>
                                            <div className={`tariffs__choice_small_circle ${tariffConnectionValue === i.type ? "active" : ''}`} />
                                        </div>
                                    </a>
                                } */}
                            </React.Fragment>
                        ))}
                    </div>

                    <div className="tariffs__block_input_sliders">
                        <div className="sum">
                            <div className="filter_rates__label">СТОИМОСТЬ ТАРИФА, <span>₽ В МЕСЯЦ</span></div>
                            <div className="wrapper">
                                <input
                                    type="text"
                                    className="b-input-text__input"
                                    value={user_cost}
                                    onChange={this.onChangeUserPrice}
                                    disabled={cost_type}
                                />
                                <div className={`sum_wrapper ${isSm ? "small" : ''}`}>
                                    <button
                                        className="lock_btn"
                                        onClick={this.onToggleFixedPrice}
                                        disabled={this.props.isConstructorOk}
                                    >
                                        {cost_type
                                            ? <Icon
                                                name="icon_lock"
                                                className="lock"
                                            />
                                            : <Icon
                                                name="icon_unlock"
                                                className="unlock"
                                            />
                                        }
                                        {cost_type
                                            ? isSm
                                                ? 'Разблокировать' : 'Разблокировать цену'
                                            : isSm
                                                ? 'Зафиксировать' : 'Зафиксировать цену'
                                        }
                                    </button>
                                    {settings.fix_price_tooltip
                                        && (
                                            <div className='sms__wrap_tooltip'>
                                                <TooltipQuestion text={settings.fix_price_tooltip} />
                                            </div>
                                        )}
                                </div>
                            </div>

                            {priceMinMax &&
                                (<div className={`sum_min_max ${priceMinMax ? "active" : ''}`}>
                                    {priceMinMaxValue}
                                </div>)
                            }
                            {tooltipOn &&
                                (<div
                                    ref={this.tooltipRef}
                                    className={`sum_tooltip ${tooltipOn ? "active" : ''} ${priceMinMax ? "indent" : ''}`}
                                >
                                    <div className="sum_tooltip_text">
                                        {tooltipValue}
                                    </div>
                                    <div className="sum_tooltip_btns">
                                        <button
                                            className="sum_tooltip_btns_btn"
                                            onClick={() => this.onTooltipClick()}
                                        >
                                            Добавить
                                        </button>
                                        <button
                                            className="sum_tooltip_btns_btn"
                                            onClick={() => this.setState({ tooltipOn: false })}
                                        >
                                            Не интересно
                                        </button>
                                    </div>
                                </div>)
                            }
                        </div>

                        <div className={`minutes ${cost_type ? "fixed" : ''}`}>
                            <div className="filter_rates__label">звонки НА НОМЕРА РФ, <span>минут В МЕСЯЦ</span></div>
                            <RangeInput
                                type={'svoiMinutes'}

                                min={cost_type ? fixMinValMinutes : 0}
                                // min={0}

                                max={cost_type ? fixMaxValMinutes : maxValMinutes}
                                // max={maxValMinutes}
                                marks={cost_type ? fixMarksObjectMinutes : marksObjectMinutes}
                                // marks={marksObjectMinutes}
                                stepSlider={cost_type ? null : minutes_step}
                                // stepSlider={minutes_step}
                                dots={true}

                                value={data_unlim === 1 && cost_type
                                    ? maxFixedPrice
                                        ? axisX[axisXVal] : fixMinValMinutes
                                    : axisX[axisXVal]}
                                // value={axisX[axisXVal]}
                                valueSvoi={data_unlim === 1 && cost_type
                                    ? maxFixedPrice
                                        ? valueMinutes : fixMinValMinutes
                                    : valueMinutes}
                                // valueSvoi={valueMinutes}

                                onChange={this.onChangeRange('axisXVal')}
                                onFocus={this.onFocus('minutes')}
                                onBlur={this.onBlur('minutes')}
                                onKeyDown={(e) => this.onKeyDown(e, 'minutes')}
                                onAfterChange={
                                    () => this.onAfterChangeSlider('minutes', cost_type ? fixMarksObjectMinutes : marksObjectMinutes, axis.x[axisXVal], 0, 0)
                                }
                                // onAfterChange={() => this.onAfterChangeSlider('minutes', marksObjectMinutes, axis.x[axisXVal], roundingLowM, roundingHighM)}
                                readOnly={cost_type}
                            />
                        </div>

                        <div className={`traffic ${cost_type ? "fixed" : requiredOption ? 'infinity' : ''} ${cost_type && isZero ? "infinity" : ''}`}>
                            <div className="filter_rates__label">ИНТЕРНЕТ-ТРАФИК, <span>ГБ В МЕСЯЦ</span></div>
                            <RangeInput
                                type={'svoiGb'}
                                unlim={requiredOption}
                                min={cost_type ? fixMinValInternet : 1}
                                // min={1}

                                max={cost_type ? fixMaxValInternet : maxValInternet}
                                // max={maxValInternet}
                                marks={cost_type ? fixMarksObjectGb : marksObjectGb}
                                // marks={marksObjectGb}
                                stepSlider={cost_type ? null : svoiGb_step}
                                // stepSlider={svoiGb_step}
                                dots={true}
                                // value={data_unlim === 1
                                //     ? (cost_type ? fixMaxValInternet : maxValInternet)
                                //     : axisYVal > yLength - 1
                                //         ? axisYVal
                                //         : axis && axis.y[axisYVal]
                                // }
                                value={gbUrl}
                                // value={data_unlim === 1 ? maxValInternet : axisYVal > yLength - 1 ? axisYVal : axis && axis.y[axisYVal]}

                                valueSvoi={data_unlim === 1 ? (cost_type ? fixMaxValInternet : maxValInternet) : valueSvoiGb}
                                // valueSvoi={data_unlim === 1 ? maxValInternet : valueSvoiGb}
                                onChange={this.onChangeRange('axisYVal')}
                                onFocus={this.onFocus('svoiGb')}
                                onBlur={this.onBlur('svoiGb')}
                                onKeyDown={(e) => this.onKeyDown(e, 'svoiGb', cost_type ? fixMaxValInternet : maxValInternet)}
                                // onKeyDown={(e) => this.onKeyDown(e, 'svoiGb', maxValInternet)}
                                onAfterChange={() => this.onAfterChangeSlider('svoiGb', cost_type ? fixMarksObjectGb : marksObjectGb, axisY[axisYVal], 0, 0)}
                                // onAfterChange={() => this.onAfterChangeSlider('svoiGb', marksObjectGb, axisY[axisYVal], roundingLowGb, roundingHighGb)}
                                // unit={'ГБ'}
                                valueData={data_unlim}
                                readOnly={cost_type}
                            />
                        </div>
                    </div>
                    <div className="tariffs__block_additional_services">
                        {optionSmsArr.map((i) => (
                            <div
                                key={i.type}
                                className="sms__choice_container"
                                onClick={() => this.toggleSmsPack(i.type)}
                            >
                                <div className={`sms__choice_big_circle ${currentSmsPack === i.type ? "active" : ''}`}>
                                    <div className={`sms__choice_small_circle ${currentSmsPack === i.type ? "active" : ''}`} />
                                </div>
                                <div>
                                    {i.name}
                                </div>
                                {i.description
                                    && (
                                        <div className='sms__wrap_tooltip'>
                                            <TooltipQuestion text={i.description} />
                                        </div>
                                    )}
                            </div>
                        ))}
                    </div>

                    <AdditionalServices
                        optionOtherArr={optionOtherArr}
                        maxFixedPrice={maxFixedPrice}

                        checkboxClick={this.checkboxClick}

                        nameData="data_unlim"
                        valueData={data_unlim}
                        onChangeData={optionText
                            ? () => this.openModal()
                            : () => this.onCheckboxChange('data_unlim')
                        }

                        nameRouter="router"
                        valueRouter={router}
                        onChangeRouter={() => this.onCheckboxChange('router')}

                        nameSocial="social_unlim"
                        valueSocial={social_unlim}
                        onChangeSocial={() => this.onCheckboxChange('social_unlim')}

                        nameVoice="motiv_voice_unlim"
                        valueVoice={motiv_voice_unlim}
                        onChangeVoice={() => this.onCheckboxChange('motiv_voice_unlim')}

                        nameYoutube="youtube_unlim"
                        valueYoutube={youtube_unlim}
                        onChangeYoutube={() => this.onCheckboxChange('youtube_unlim')}

                        nameIvi="ivi"
                        valueIvi={ivi}
                        onChangeIvi={() => this.onCheckboxChange('ivi')}

                        nameHome="home_region_checked"
                        valueHome={home_region_checked}
                        onChangeHome={() => this.onCheckboxChange('home_region_checked')}

                        currentSmsPack={currentSmsPack}
                        optionSmsArr={optionSmsArr}
                        toggleSmsPack={this.toggleSmsPack}

                        chOn={chOn}
                        disabledRqs={disabledRqs}
                    />

                </div>

                <div className="tariffs__block_right">
                    <TariffCalculatorNew
                        name={name}
                        onTariffNameChange={this.props.onTariffNameChange}
                        inputTariffNameRef={inputTariffNameRef}
                        onBlurName={this.props.onBlurName}
                        openModal={this.props.openModal}
                        maxFixedPrice={maxFixedPrice}
                        dataSliderParams={dataSliderParams}
                        constrUrlLink={constrUrlLink}

                        toggleInputValueTariff={this.props.toggleInputValueTariff}
                        toggleTariffBtn={this.props.toggleTariffBtn}
                        constructorParams={constructorParams}
                        settings={settings}
                        dataForUrl={dataForUrl}
                        onClickConnect={this.openingConnectionForm}

                        checkboxClick={this.checkboxClick}

                        nameData="data_unlim"
                        valueData={data_unlim}
                        onChangeData={optionText
                            ? () => this.openModal()
                            : () => this.onCheckboxChange('data_unlim')
                        }

                        nameRouter="router"
                        valueRouter={router}
                        onChangeRouter={() => this.onCheckboxChange('router')}

                        nameSocial="social_unlim"
                        valueSocial={social_unlim}
                        onChangeSocial={() => this.onCheckboxChange('social_unlim')}

                        nameVoice="motiv_voice_unlim"
                        valueVoice={motiv_voice_unlim}
                        onChangeVoice={() => this.onCheckboxChange('motiv_voice_unlim')}

                        nameYoutube="youtube_unlim"
                        valueYoutube={youtube_unlim}
                        onChangeYoutube={() => this.onCheckboxChange('youtube_unlim')}

                        nameIvi="ivi"
                        valueIvi={ivi}
                        onChangeIvi={() => this.onCheckboxChange('ivi')}

                        nameHome="home_region_checked"
                        valueHome={home_region_checked}
                        onChangeHome={() => this.onCheckboxChange('home_region_checked')}

                        currentSmsPack={currentSmsPack}
                        optionSmsArr={optionSmsArr}
                        toggleSmsPack={this.toggleSmsPack}

                        chOn={chOn}
                        disabledRqs={disabledRqs}
                        activateTariff={this.props.activateTariff}
                    />
                </div>
                {this.renderModal()}
            </div>
        )
    }
}

export default connect(
    state => ({
        activation: state.activation,
        forms: state.forms,
        isGuest: state.client.isGuest,
        tariffConstructor: state.tariffConstructor,
        tariffConnectionValue: state.common.tariffConnectionValue,
        isConstructorOk: state.tariffConstructor.isConstructorOk,
        currentTariff: state.client.clientInfo.tariff,
        clientInfo: state.client.clientInfo,
    }),
    (dispatch) => {
        return {
            checkWord: (word) => dispatch(checkWord(word)),
            clearWord: () => dispatch(clearWord()),
            clearActivationData: () => dispatch(clearActivationData()),
            tariffConnectionToggleAction: (data) => dispatch(tariffConnectionToggleAction(data)),
            callLogin: () => dispatch(callLogin()),
        }
    }
)(TariffConstructorNew);
