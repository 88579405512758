import { createBrowserHistory, createMemoryHistory } from "history";
import PropTypes from "prop-types";
import React, { Component, createRef, Fragment } from 'react';
import { connect } from "react-redux";
import HeaderMain from '../../components/Header/HeaderMain';
import HeaderMegaMenu from "../../components/Header/HeaderMegaMenu";
import HeaderNav from '../../components/Header/HeaderNav';
import HeaderTop from '../../components/Header/HeaderTop';
import Icon from '../../components/common/Icon';
import SearchInput from '../../components/common/SearchInput';
import LoadingBar from 'react-redux-loading-bar';
import { auth, callLogin, logout, recovery, clearState, callLogout } from "../../actions/client";
import {
    toggleSearchInputAction,
    stateSearchInputAction,
} from '../../actions/searchAction';
import { setCurrentStep } from "../../actions/stepActions";
import { getPathWithoutRegion } from "../../tools/url-helper";
import BalansBlock from "../../components/BalansBlock/BalansBlock";
import { regionsListB2B } from "../../actions/regions";

class Header extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            megaMenuOpened: false,
            activeIdx: {
                col: -1,
                block: -1
            },
            mainMenu: props.mainMenu.map((item) => {
                switch (item.link) {
                    case '#become-client':
                        return {
                            ...item,
                            func: context.b2bModalToggle
                        }
                    default:
                        return item;
                }
            })
        }


        this.refSearch = React.createRef();
    }

    static contextTypes = {
        locationPath: PropTypes.string,
        b2bModalToggle: PropTypes.func,
        selectedRegion: PropTypes.object
    };
    coordTouchY = createRef();
    deltaY = createRef();

    toggleCollapse = (idx) => {
        this.setState((prevState) => {
            let activeIdx = JSON.stringify(prevState.activeIdx) === JSON.stringify(idx) ? { col: -1, block: -1 } : idx;
            return {
                activeIdx: activeIdx
            }
        })
    };

    // отображаем поле поиска кликая по увеличительному стеклу справа от меню
    onOpenSearch = () => {
        this.props.toggleSearchInputAction(true);
        this.props.stateSearchInputAction('');

        setTimeout(() => {
            this.refSearch.current.focus();
        }, 200);

    }

    //#region [ Управление Overlay с состоянием мега-меню ]
    updateOverlay = (shouldBeOpen) => {
        const overlayElement = document.getElementById('root');
        if (overlayElement) {
            if (shouldBeOpen) {
                overlayElement.classList.add('overlay-active');
            } else {
                overlayElement.classList.remove('overlay-active');
            }
        }
    };

    checkScroll = (e) => {
        let ticking = false;

        const path = getPathWithoutRegion(this.context.locationPath);
        // определяем, что пользователь находится на детальной странице услуг
        const servicePath = (path.indexOf('services/') !== -1 && path.split('/').length > 1) ? path.split('/').slice(-1)[0] : '';

        if (
            !ticking &&
            window.innerWidth < 1024 &&
            document.querySelector('.page__header-holder') &&
            path !== '' && path!=='internet/4g' && (this.props.currentStep || path !== 'mnp')
            // (!servicePath || (servicePath === 'cashback' || servicePath === 'all' || servicePath === 'services'))
        ) {
            // event throtteling
            window.requestAnimationFrame(() => {
                this.deltaY.current = window.scrollY - this.coordTouchY.current;
                this.coordTouchY.current = window.scrollY;

                if (window.scrollY <= +20) {
                    document.querySelector('.page__header-holder').classList.add('header-sticky');
                    document.querySelector('.page__header-holder').classList.remove('header-visible');
                } else if (window.scrollY >= +20) {
                    document.querySelector('.page__header-holder').classList.remove('header-sticky');
                    document.querySelector('.page__header-holder').classList.add('header-visible');
                }
                if (this.deltaY.current < 0) {
                    document.querySelector('.page__header-holder').classList.add('header-sticky');
                    document.querySelector('.page__header-holder').classList.remove('header-visible');

                } else if (this.deltaY.current > 0) {
                    document.querySelector('.page__header-holder').classList.remove('header-sticky');
                }
                ticking = false;
            });
            ticking = true;
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.openMenu !== this.props.openMenu) {
            this.updateOverlay(this.props.megaMenuOpened);
        }

        const path = getPathWithoutRegion(this.context.locationPath);

        // определяем, что пользователь находится на детальной странице услуг
        const servicePath = (path.indexOf('services/') !== -1 && path.split('/').length > 1) ? path.split('/').slice(-1)[0] : '';
        if (
            typeof document !== 'undefined' &&
            typeof window !== 'undefined' &&
            document.querySelector('.page__header-holder')
            // (!servicePath || (servicePath === 'cashback' || servicePath === 'all' || servicePath === 'services'))
        ) {
            if (window.innerWidth >= 1024) {

                if (path === 'internet/4g') {
                    document.querySelector('.page__header-holder').classList.remove('header-sticky');
                    return
                }

                if (path === 'mnp' && !this.props.currentStep) {
                    document.querySelector('.page__header-holder').classList.remove('header-sticky');
                    return
                }
                if (path !== '') {
                    document.querySelector('.page__header-holder').classList.add('header-sticky');
                } else {
                    document.querySelector('.page__header-holder').classList.remove('header-sticky');
                }
            }
        }
    }

    componentWillUnmount() {
        this.updateOverlay(false);
        if (typeof window !== 'undefined') window.removeEventListener('scroll', this.checkScroll);
    }
    //#endregion

    //#region  [ Управление Overlay с состоянием меню ]
    componentDidMount() {
        // активировать затемнение и плашку для ознакомления с новинками сайта
        // if (!localStorage.getItem('hasSeenNewMenu')) {
        //     this.setState({ showNewMenuHighlight: true });
        //     localStorage.setItem('hasSeenNewMenu', 'true'); // Set only once
        // }
        this.coordTouchY.current = 0;
        this.deltaY.current = 0;
        if (typeof window !== 'undefined') window.addEventListener('scroll', this.checkScroll);
    }

    closeHighlight = () => {
        this.setState({ showNewMenuHighlight: false });
        this.updateOverlay(false);
    }
    //#endregion

    render() {
        const { showNewMenuHighlight } = this.state;

        let {
            routes,
            isB2b,
            megaMenuOpened,
            stateToggleSearchInput,
            allAppeals,
        } = this.props;

        let motivOnMap = routes ? routes.list.find(x => x.sys_name === 'motivOnMapRoute') : null;
        let motivOnMapRoute = motivOnMap ? motivOnMap.route : '/motiv-on-map';

        let className = this.props.headerClassName;

        const history = typeof document === 'undefined' ? createMemoryHistory() : createBrowserHistory();
        // const findUrl = history.location.pathname.includes('services/');
        const findUrl = history.location.pathname.includes('tariffs');
        const findUrl2 = history.location.pathname.includes('legal-information');

        if (typeof document !== 'undefined') {
            let header = document.getElementsByTagName("header")[0];
            // определяем что мы находимся на детальной странице услуг и добавляем нужный нам класс .page__header-holder_float
            // перезаписаавание классов для десктопа            
            // header.className = `page__header-holder ${findUrl ? className : ''}`;
            // header.className = 'page__header-holder' + className;
            if (isB2b && findUrl2) {
                header.className = 'page__header__b2b ' + 'page__header-holder'
            }
        }

        let activation = this.props.activation;

        if (activation.mnpConstructor) {
            className = '';
        }
        let currentRegion = this.context.selectedRegion.alias;
        const curentPath = this.context.locationPath.split("/")[this.context.locationPath.split("/").length - 1];

        // проверяем путь, если переходим с главной страницы на mnp то удалаем классы 
        // if (curentPath === 'mnp') {
        //     className = ''
        // }
        // console.log('megaMenuOpened',megaMenuOpened);

        return (
            <Fragment>
                <header className={(isB2b ? "page__header__b2b " : "") + "page__header-holder" + (isB2b && megaMenuOpened ? " page__header-holder_float__b2b" : "") + (!findUrl ? className : '')} onClick={this.closeHighlight}>
                    <div className={"b-header b-header_adapt_default" + (this.props.megaMenuOpened ? " b-header_menu-opened" : "")}>
                        <LoadingBar style={{ backgroundColor: '#f37021', height: '3px', zIndex: '1001', position: 'fixed' }} />
                        <div className="header-top-for-stick">
                            <HeaderTop isB2b={isB2b} motivOnMapRoute={motivOnMapRoute} locationPath={this.context.locationPath} />
                            <HeaderMain openMenu={this.props.openMenu}
                                login={this.props.login}
                                callLogin={this.props.callLogin}
                                logout={this.props.logout}
                                callLogout={this.props.callLogout}
                                recover={this.props.recover}
                                clear={this.props.clear}
                                isB2b={isB2b}
                                {...this.props.client}
                                {...this.props.currentTariff}
                                qrText={this.props.qrText}
                                regionSlug={this.context.selectedRegion.alias === this.props.clientRegionSlug ? null : this.props.clientRegionSlug}
                                showNewMenuHighlight={showNewMenuHighlight}
                                closeHighlight={this.closeHighlight}
                                allAppeals={allAppeals}
                            />
                        </div>
                        <HeaderMegaMenu
                            openMenu={this.props.openMenu}
                            onOpenSearch={this.onOpenSearch}
                            toggleCollapse={this.toggleCollapse}
                            activeIdx={this.state.activeIdx}
                            isGuest={this.props.client.isGuest}
                            locationPath={this.context.locationPath}
                            mainMenu={this.state.mainMenu}
                        />
                        {stateToggleSearchInput
                            && (
                                <div className='toggle_search_input page__container flex items-center'>
                                    <div className="b-short-menu__level-label-menu" onClick={this.props.openMenu}>
                                        <div className="b-header__burger-btn flex items-center gap-2">
                                            <p className="b-header__burger-btn-label m-0">
                                                <Icon name={'icon_menu-burger'} className={'b-header__burger u-icon u-icon_menu-burger'} />
                                                <Icon name={'icon_cross'} className={'b-header__close-icon u-icon u-icon_cross'} />
                                                Меню
                                            </p>
                                        </div>
                                    </div>

                                    <div className="page__header__b2b__wrap_search_input w-full pl-16">
                                        <SearchInput
                                            refSearch={this.refSearch}
                                        />
                                    </div>
                                </div>
                            )}
                        {!stateToggleSearchInput
                            && (
                                <HeaderNav
                                    openMenu={this.props.openMenu}
                                    mainMenu={this.state.mainMenu}
                                    toggleSearchInputAction={this.props.toggleSearchInputAction}
                                    stateSearchInputAction={this.props.stateSearchInputAction}
                                    onOpenSearch={this.onOpenSearch}

                                    isB2b={isB2b}
                                    showNewMenuHighlight={showNewMenuHighlight}
                                    closeHighlight={this.closeHighlight}
                                />
                            )}
                    </div>

                    {!isB2b && (
                        showNewMenuHighlight && (
                            <div className="overlay-visitor" onClick={this.closeHighlight}></div>
                        )
                    )}
                </header>

                {(curentPath !== '' && curentPath !== '4g' && curentPath !== 'mnp' && curentPath !== currentRegion) &&
                    <div className="wrap_balans_user">
                        <BalansBlock
                            openMenu={this.props.openMenu}
                            login={this.props.login}
                            callLogin={this.props.callLogin}
                            logout={this.props.logout}
                            callLogout={this.props.callLogout}
                            recover={this.props.recover}
                            clear={this.props.clear}
                            isB2b={isB2b}
                            {...this.props.client}
                            {...this.props.currentTariff}
                            qrText={this.props.qrText}
                            regionSlug={this.context.selectedRegion.alias === this.props.clientRegionSlug ? null : this.props.clientRegionSlug}
                            showNewMenuHighlight={showNewMenuHighlight}
                            closeHighlight={this.closeHighlight}
                            allAppeals={allAppeals}
                        />
                    </div>
                }
            </Fragment>
        )
    }
}

export default connect(
    state => ({
        activation: state.activation,
        client: state.client,
        routes: state.routes,
        currentTariff: state.client.clientInfo.tariff,
        clientRegionSlug: state.client.clientInfo.region && state.client.clientInfo.region.alias || "",
        qrText: state.settings.qr_code_description,
        stateToggleSearchInput: state.searchReducer.stateToggleSearchInput,
        stateSearchHint: state.searchReducer.stateSearchHint,
        allAppeals: state.client.allAppeals || [],
        currentStep: state.step.currentStep,
        regionsB2B: state.regions
    }),
    dispatch => {
        return {
            login: (data, rememberMe) => dispatch(auth(data, rememberMe)),
            callLogin: () => dispatch(callLogin()),
            recover: (data) => dispatch(recovery(data)),
            logout: () => dispatch(logout()),
            callLogout: () => dispatch(callLogout()),
            clear: () => dispatch(clearState()),
            toggleSearchInputAction: (data) => dispatch(toggleSearchInputAction(data)),
            searchHintAction: (businessLine, term, regionId) => dispatch(searchHintAction(businessLine, term, regionId)),
            clearSearchHintAction: () => dispatch(clearSearchHintAction()),
            stateSearchInputAction: (data) => dispatch(stateSearchInputAction(data)),
            setCurrentStep: (step) => dispatch(setCurrentStep(step)),
        }
    }
)(Header);

